import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Forms/Login";
import Register from "../Forms/Register";
import ForgotPassword from "../Forms/ForgotPassword";

import Sidebar from "../Pages/Sidebar";

import { Context } from "../Context/Context";

const PageRoutes = () => {
  const { isLoggedIn, } = useContext(Context);

  return (
    <>
      {!isLoggedIn ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <>
          <Sidebar />
        </>
      )}
    </>
  );
};

export default PageRoutes;
