import React from "react";
import { Modal, Box, Typography, Grid, Button } from "@mui/material";

const DeleteConfirm = ({ open, onClose, onConfirm }) => {


  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
        }}
      >
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" gutterBottom>
            Confirm  to Delete
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center" gutterBottom>
            Are you sure you want to delete.This action cannot be undone.
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="end" gap={1}>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={onConfirm}>
              Yes Delete
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteConfirm;





