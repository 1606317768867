import React, { useContext, useEffect, useState } from "react";
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  List, ListItem, ListItemText, ListItemIcon, Collapse, Toolbar, IconButton, Box, Badge, Typography, Menu,
  MenuItem, Tooltip, Avatar,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Notifications, AccountCircle,
  CurrencyRupee,
} from "@mui/icons-material";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import {
  BadgeIndianRupee, ChevronDown, ChevronDownIcon, ChevronUp, CreditCard,
  FileText, IndianRupee, LayoutDashboard, MailQuestion, TextCursorInput, Users,
} from "lucide-react";

import PageInnerRoutes from "../Routes/PageInnerRoutes";
import { Context } from "../Context/Context";
import UserProfile from "./User/UserProfile";
import ChangePassword from "../Forms/ChangePassword";
import apiUrl from "../Config";
import logo from '../Assets/logo.png';
import { useLocalizationContext } from "@mui/x-date-pickers/internals";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   position: "fixed",
//   top: -10,
//   backgroundColor: "#ffffff",
//   zIndex: 1100,
//   ...theme.mixins.toolbar,
// }));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const [open, setOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openChangePasword, setOpenChangePassword] = useState(false);
  const navigate = useNavigate();
  const { getUserProfile, userProfile, setIsLoggedIn } = useContext(Context);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const activepathname = useLocation()

  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const specificImagePaths = userProfile?.files?.filter(file => file?.fileType_Id === 2)?.map(file => file?.filePath);

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true)
  }

  const handleCloseProfileModal = () => {
    setOpenProfileModal(false)
  }

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true)
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false)
  }
  const handleSubMenuToggle = (label) => {
    setActiveMenu(label);  // Set the active menu here
    setOpenSubMenu((prevState) => {
      const newState = {};
      for (const key in prevState) {
        newState[key] = key === label ? !prevState[key] : false;
        setActiveSubMenu(false)
      }
      if (!prevState[label]) {
        newState[label] = true;

      }
      return newState;
    });
    localStorage.setItem('activeMenu', label)
    setOpen(true);
  };

  const handleSubmenuActive = (label) => {
    setActiveSubMenu(label)
    localStorage.setItem('activeSubMenu', label)
  }

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenSubMenu({});
  };


  const menuItems = [
    { label: "Profile", action: "porfile" },
    { label: "Change Password", action: "changepassword" },
    { label: "Logout", action: "logout" },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    if (item.action === "changepassword") {
      handleOpenChangePassword()
    }
    if (item.action === "porfile") {
      handleOpenProfileModal()
    }
    if (item.action === "logout") {
      localStorage.removeItem("userToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem('activeMenu')
      localStorage.removeItem('activeSubMenu')
      localStorage.removeItem('userName')
      setIsLoggedIn(false);
      navigate("/");
    }
    handleClose();
  };



  useEffect(() => {
    if (activepathname?.pathname === '/') {
      localStorage.setItem('activeMenu', 'Dashboard')

      localStorage.setItem('activeSubMenu', '')
    }


  }, [activepathname?.pathname])

  const drawerItems = [
    {
      label: "Dashboard",
      icon: <LayoutDashboard size={18} />,
      link: "/",
    },
    {
      label: "Manage Users",
      icon: <Users size={18} />,

      children: [
        { label: "Users", link: "/users" },
        { label: "Role Manager", link: "/role" },
        { label: "Training Videos", link: "/trainingvideos" },
        { label: "Policies", link: "/policies" },
      ],
    },
    {
      label: "Manage Services",
      icon: <TextCursorInput size={18} />,
      children: [
        { label: "Display Services", link: "/displayServices" },
        { label: "Service Fields", link: "/servicefields" },
        { label: "Faqs", link: "/faq" },
        { label: "Zones", link: "/zones" },
        { label: "Languages", link: "/languages" },
      ],
    },
    {
      label: "Membership Plans",
      icon: <CreditCard size={18} />,
      link: "/membershipPlans",
    },
    {
      label: "Bookings",
      icon: <FileText size={18} />,
      link: "/bookingslist",
    },
    {
      label: "Transactions",
      icon: <BadgeIndianRupee size={18} />,
      children: [
        { label: "Booking Transactions", link: "/bookingtransactions" },
        { label: "Cancel & Refund", link: "/cancelRefund" },
      ],
    },

    {
      label: "Prices",
      icon: <IndianRupee size={18} />,
      link: "/prices",
    },
    {
      label: "Enquiries",
      icon: <MailQuestion size={18} />,
      link: "/enquiries",
    }
  ];

  useEffect(() => {
    const savedActiveMenu = localStorage.getItem("activeMenu");
    const savedActiveSubMenu = localStorage.getItem("activeSubMenu");

    if (savedActiveMenu) {
      setActiveMenu(savedActiveMenu);
    }

    if (savedActiveSubMenu) {
      setActiveSubMenu(savedActiveSubMenu);
    }

    getUserProfile();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* dash board navbar */}
        <AppBar position="fixed" open={open} sx={{ boxShadow: "none" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Sevaki Admin
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, }} />

            <Toolbar>
              <IconButton color="inherit">
                <Badge badgeContent="">
                  <Notifications />
                </Badge>
              </IconButton>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                {userProfile ? (
                  <>
                    {userProfile.userdetails?.firstName}
                    <Avatar sx={{ width: "30px", height: "30px", border: "1px solid white" }} src={`${apiUrl}/${specificImagePaths}`} />
                  </>
                ) : (
                  <AccountCircle />
                )}
              </Box>

              <IconButton color="inherit" onClick={handleClick}>

                <ChevronDownIcon />
              </IconButton>
            </Toolbar>
          </Toolbar>
        </AppBar>
        {/* side menus */}
        <Drawer variant="permanent" open={open} sx={{

        }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 2,

            }}
          >
            <Box
              className="logo"
              sx={{ position: "sticky", top: 0, zIndex: 1100 }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Avatar variant="square" src={logo} sx={{ width: 30, height: 30 }}></Avatar>
                <Typography variant="h5">Sevaki</Typography>

              </Box>

            </Box>
            <IconButton onClick={handleDrawerClose}>
              {true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          {/* <Divider /> */}

          <List>
            {drawerItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem sx={{ paddingTop: "0px", paddingRight: "0px" }}
                  button
                  onClick={() => handleSubMenuToggle(item.label)}
                  component={Link}
                  to={item.link}
                >
                  <Tooltip title={item.label} placement="right">
                    <ListItemIcon
                      sx={{
                        maxWidth: "35px",
                        minWidth: "37px",
                        color: activeMenu === item.label ? "#7E62CC" : "#000000"
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>

                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography variant="h6" color={activeMenu === item.label ? "#7E62CC" : "#000000"} fontWeight={activeMenu === item.label ? "500" : "360"}
                      >
                        {item.label}
                      </Typography>
                    }
                  />
                  {item.children && (
                    <IconButton>
                      {openSubMenu[item.label] ? <ChevronUp /> : <ChevronDown />}
                    </IconButton>
                  )}
                </ListItem>


                {item.children && (
                  <Collapse
                    in={openSubMenu[item.label]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.children.map((child, childIndex) => (
                        <ListItem
                          key={childIndex}
                          button
                          component={Link}
                          to={child.link}
                          onClick={() => handleSubmenuActive(child.label)}
                          sx={{ marginLeft: "36px", paddingTop: "0px", paddingBottom: "0px" }}

                        >

                          <ListItemText
                            primary={

                              <Typography variant="body1" color={activeSubMenu === child.label ? "#7E62CC" : "#000000"} fontWeight={activeSubMenu === child.label ? "500" : "360"}>
                                {child.label}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
        {/* provide menu popup */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 0.5px 2px rgba(0,0,0,0.10))",
              mt: 2,
              ml: 1,
              borderRadius: "10px",
              width: "200px",
              border: "1px solid #E4E4E4",
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
                borderTop: "1px solid #E4E4E4",
                borderLeft: "1px solid #E4E4E4",
              },
            },
          }}
          MenuListProps={{
            sx: {
              padding: "10px",
              paddingTop: "10px",
              paddingBottom: "8px",
            },
          }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
              <Typography sx={{ fontSize: "15px", paddingLeft: "9px" }}>
                {item.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Box sx={{ mt: "70px" }}>
            <PageInnerRoutes />
          </Box>
        </Box>
      </Box>
      {openProfileModal && (
        <UserProfile
          open={openProfileModal}
          onClose={handleCloseProfileModal}
        />


      )}
      {openChangePasword && (
        <ChangePassword
          open={openChangePasword}
          onClose={handleCloseChangePassword}
        />
      )
      }


    </>
  );
};

export default Sidebar;
