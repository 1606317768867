
import React, { useContext, useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Box,
  TextField, IconButton, MenuItem, Select, InputLabel, FormControl, Grid, Menu, Divider, CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Context";
import axios from "axios";
import apiUrl from "../../Config";
import UserVerificationSlot from "../UserVerificationslot/UserVerficationslot";
import AddBankDetails from "../BankDetails/AddBankDetails";
import SuccessPopup from "../../Common/SuccessPopup";
import { BadgeIndianRupee, CreditCard, MoreVerticalIcon, Pencil, RotateCcw, ShieldAlert, Trash2 } from 'lucide-react';
import DeleteConfirm from "../../Common/DeleteConfirm";
const User = () => {
  const { roles, serviceTypes, zones, token, successPopup, succesMessage, setSuccessPopup,
    closePopup, setSuccesMessage, isConfirmDeleteModelOpen, setIsConfirmDeleteModelOpen, itemIdToDelete, setItemIdToDelete
  } = useContext(Context);
  const [searchInput, setSearchInput] = useState("");
  const [user, setUser] = useState();
  const [selectedRole, setSelectedRole] = useState("");
  const [subservices, setSubservices] = useState([]);
  const [selectedZone, setSelectedZone] = useState("")
  const [selectedService, setSelectedService] = useState("")
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedSubService, setSelectedSubService] = useState("");
  const [selectedKYCStatus, setSelectedKYCStatus] = useState("");
  const [selectedActiveStatus, setSelectedActiveStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [selectedFields, setSelectedFields] = useState([]);
  const [openActiveModal, setOpenActiveModal] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false)
  // const [selectedIds, setSelectedIds] = useState([]);
  const handleOpenModal = (type, user = null) => {
    setSelectedUser(user);
    setOpenActiveModal(type);
  };
  // get sebservices by service id and zone id
  const getSubservices = async (serviceId, zoneId) => {
    if (serviceId && zoneId) {
      try {
        const response = await axios.get(
          `https://api.sevaki.in/servicetypes/0/getservicesubtypesbyserviceidwithzoneid?serviceId=${serviceId}&zoneId=${zoneId}`
        );
        if (response.data.status === true) {
          setSubservices(response.data.subservices);
        }
      } catch (error) {
        if (error?.response?.data?.status === 401) {
          setSuccesMessage(error?.response?.data?.title)
          setSuccessPopup(true)
        } else {
          setError(error?.response?.data?.message || "Something went wrong");
        }
      }
    }
  };

  // handle zone id change
  const handleZoneChange = (event) => {
    const zoneId = event.target.value;
    setSelectedZone(zoneId);
    if (zoneId && selectedService) {
      getSubservices(selectedService, zoneId);
    }
  };

  // Handle service change
  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    setSelectedService(serviceId);
    if (serviceId && selectedZone) {
      getSubservices(serviceId, selectedZone);
    }
  };
  useEffect(() => {
    getSubservices(selectedService, selectedZone)
  }, [])

  const handleNextPage = () => {
    if (hasMore) {
      setPageNumber(pageNumber + 1);
    }
  }

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  // handle to search to get the data
  const handleSearch = async (includeFields = true) => {
    setLoading(true)
    let requestData = {
      searchText: searchInput,
      pageSize: 5,
      pageNumber: pageNumber,
      ...(selectedRole && includeFields && { roleId: selectedRole }),
      ...(selectedSubService && includeFields && { subServiceId: selectedSubService }),
      ...(selectedZone && includeFields && { defaultZoneId: selectedZone }),
      ...(selectedActiveStatus !== "" && includeFields && { isActiveOnly: selectedActiveStatus }),
      ...(selectedKYCStatus !== "" && includeFields && { isKYCVerified: selectedKYCStatus }),
    };
    try {
      const response = await axios.post(
        apiUrl + "/users/searchusers",
        requestData,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHasMore(response?.data?.users?.length === 5);
      if (response?.data?.status === true) {
        setUser(response?.data?.users);
      }

    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteUser = (userId) => {
    setItemIdToDelete(userId)
    setIsConfirmDeleteModelOpen(true)
  }

  // delete user by Id
  const handleDeleteUser = async () => {
    setIsConfirmDeleteModelOpen(false)
    try {
      const response = await axios.delete(apiUrl + `/users/delete?userId=${itemIdToDelete}`, {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === true) {
        setSuccesMessage(response?.data?.message)
        setSuccessPopup(true)
        setUser()
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  const addNewUser = () => {
    navigate("/adduser");
  };

  const handleMenuOpen = (event, index, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleMenuClose = () => {
    setAnchorEl(false);
    setSelectedUserId(null);
  };

  const handleEditUser = (userId) => {
    navigate(`/adduser`, { state: { id: userId } });
  };

  const handleSearchDetails = (includeFields = true) => {
    handleSearch(includeFields);
    setPageNumber(1)
  }
  const fieldOptions = [
    { label: "Role", value: "role" },
    { label: "Zones", value: "zones" },
    { label: "KYC Status", value: "kyc" },
    { label: "Active Status", value: "active" },
    { label: "Service Type", value: "service" },
    { label: "Sub-Service Type", value: "subservice" },
  ];

  const handleFieldChange = (event) => {
    const { value } = event.target;
    setFilterOpen(false);
    const removedFields = selectedFields.filter((field) => !value.includes(field));
    removedFields.forEach((field) => {
      if (field === "zones") setSelectedZone("");
      if (field === "role") setSelectedRole("");
      if (field === "kyc") setSelectedKYCStatus("");
      if (field === "active") setSelectedActiveStatus("");
      if (field === "service") {
        setSelectedService("");
        setSelectedSubService("");
      }
      if (field === "subservice") setSelectedSubService("");
    });
    setSelectedFields(value);
    setFilterOpen(false);
  };
  const handleClearFields = () => {
    setSelectedFields([]);
    setSelectedZone("");
    setSelectedRole("");
    setSelectedKYCStatus("");
    setSelectedActiveStatus("");
    setSelectedService("");
    setSelectedSubService("");
    handleSearchDetails(false)
  };

  useEffect(() => {
    handleSearch()
  }, [pageNumber])

  return (
    <Box mt="80px">
      <Grid item xs={12} sm={6} md={2} sx={{ textAlign: "right", mb: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h4">Users List</Typography>
          <Button variant="contained" color="primary" onClick={addNewUser}>
            Add New User
          </Button>
        </Box>
      </Grid>
      <Divider />
      <Grid sx={{ display: "flex", mt: "20px" }}>
        <Grid container spacing={2} >
          {/* Search Field */}
          <Grid item xs={12} sm={5} md={4}>
            <TextField
              label="Search By / User Name"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Grid>

          {/* Select which fields to display */}
          <Grid item xs={12} sm={4} md={2}>
            <FormControl variant="outlined" size="small" fullWidth
            // sx={{width:"100px"}}
            >
              <InputLabel>Select Filters</InputLabel>
              <Select
                multiple
                value={selectedFields}
                onChange={(e) => handleFieldChange(e)}
                label="Select Filters"
                open={filterOpen}
                onOpen={() => setFilterOpen(true)}
                onClose={() => setFilterOpen(false)}

                renderValue={(selected) => selected.join(", ")}
              >
                {fieldOptions.map((field) => (
                  <MenuItem key={field.value} value={field.value}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Conditional Dropdown Rendering */}
          {selectedFields.includes("role") && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  value={selectedRole || ""}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  label="Role"
                >
                  <MenuItem value="">
                    <em>All Roles</em>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {selectedFields.includes("zones") && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Zones</InputLabel>
                <Select
                  value={selectedZone}
                  onChange={handleZoneChange}
                  label="Zones"
                >
                  <MenuItem value="">
                    <em>All Zones</em>
                  </MenuItem>
                  {zones.map((zone) => (
                    <MenuItem key={zone.id} value={zone.id}>
                      {zone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {selectedFields.includes("kyc") && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>isKYCVerified</InputLabel>
                <Select
                  value={selectedKYCStatus}
                  onChange={(e) => setSelectedKYCStatus(e.target.value)}
                  label="isKYCVerified"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {selectedFields.includes("active") && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>isActive</InputLabel>
                <Select
                  value={selectedActiveStatus}
                  onChange={(e) => setSelectedActiveStatus(e.target.value)}
                  label="isActive"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          {selectedFields.includes("service") && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Service Type</InputLabel>
                <Select
                  value={selectedService}
                  onChange={handleServiceChange}
                  label="Service Type"
                >
                  <MenuItem value="">
                    <em>All Services</em>
                  </MenuItem>
                  {serviceTypes.map((serviceType) => (
                    <MenuItem key={serviceType.id} value={serviceType.id}>
                      {serviceType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {selectedFields.includes("subservice") && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Sub-Service Type</InputLabel>
                <Select
                  value={selectedSubService}
                  onChange={(e) => setSelectedSubService(e.target.value)}
                  label="Sub-Service Type"
                  disabled={!selectedService}
                >
                  <MenuItem value="">
                    <em>Select a Sub-Service</em>
                  </MenuItem>
                  {subservices?.map((subservice) => (
                    <MenuItem key={subservice.id} value={subservice.id}>
                      {subservice.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

          )}

        </Grid>
        <Grid item xs={12} sm={6} md={2} sx={{ textAlign: "right", mb: "10px", ml: '10px' }}>
          <Box sx={{ display: "flex" }}>
            <Button onClick={() => handleSearchDetails()} variant="contained" >  Search </Button>
            {selectedFields.length > 0 && (
              <Grid item xs={12} sm={6} md={2}>
                <Button variant="contained" color="" onClick={() => handleClearFields()}  > <RotateCcw color="#e13737" /> </Button>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      )}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: "15px" }}>
          {user?.length > 0 ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Action</TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.map(({ applicationUser, roleName }, index) => (
                    <TableRow key={applicationUser.id}>
                      <TableCell>{applicationUser.firstName}</TableCell>
                      <TableCell>{applicationUser.lastName}</TableCell>
                      <TableCell>{applicationUser.email}</TableCell>
                      <TableCell>{applicationUser.phoneNumber}</TableCell>
                      <TableCell>{roleName}</TableCell>
                      <TableCell>{applicationUser.isActive && applicationUser.isKYCVerified ? "Verified" : "Not Verified"}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={(event) => handleMenuOpen(event, index, applicationUser.id)} color="primary" sx={{ padding: "0px" }}>
                          <MoreVerticalIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={anchorEl && selectedUserId === applicationUser.id}
                          onClose={handleMenuClose}
                          MenuListProps={{
                            "aria-labelledby": "more-options-button",
                          }}
                          sx={{ padding: "4px" }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleEditUser(applicationUser.id);
                              handleMenuClose();
                            }}
                          >
                            <IconButton color="primary">
                              {/* <EditIcon size={16} /> */}
                              <Pencil size={16} />
                            </IconButton>
                            <Typography variant="body2">Edit</Typography>

                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              confirmDeleteUser(applicationUser.id);
                              handleMenuClose();
                            }}
                          >
                            <IconButton color="error">
                              {/* <DeleteIcon size={16} /> */}
                              <Trash2 size={16} />
                            </IconButton>
                            <Typography variant="body2" >Delete</Typography>
                          </MenuItem>
                          <MenuItem
                            variant="contained"
                            onClick={() => { handleOpenModal('bankDetails', applicationUser); handleMenuClose(); }}

                          >
                            <IconButton color="primary">
                              <CreditCard size={16} />
                            </IconButton>
                            <Typography variant="body2" >Bank Details</Typography>
                          </MenuItem>
                          <MenuItem
                            variant="contained"

                            onClick={() => { handleOpenModal('verification', applicationUser); handleMenuClose() }}

                          >
                            <IconButton color="primary">
                              <ShieldAlert size={16} />
                            </IconButton>
                            <Typography variant="body2" >Verification Slot</Typography>
                          </MenuItem>
                          <MenuItem
                            variant="contained"
                            onClick={() =>
                              navigate("/weeklyroutines", {
                                state: { id: applicationUser?.id },
                              })
                            }
                          >
                            <IconButton color="primary">
                              <RotateCcw size={16} />
                            </IconButton>
                            <Typography variant="body2" >Weekly Routine</Typography>
                          </MenuItem>
                          <MenuItem
                            variant="contained"
                            onClick={() =>
                              navigate("/bookingtransactions", {
                                state: { id: applicationUser?.id },
                              })
                            }
                          >
                            <IconButton color="primary">
                            <BadgeIndianRupee size={16} />
                            </IconButton>
                            <Typography variant="body2" >Transactions</Typography>
                          </MenuItem>
                          <MenuItem
                            variant="contained"
                            onClick={() =>
                              navigate("/bookingslist", {
                                state: { id: applicationUser?.id },
                              })
                            }
                          >
                            <IconButton color="primary">
                            <BadgeIndianRupee size={16} />
                            </IconButton>
                            <Typography variant="body2" >Booking Details</Typography>
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}  >
              <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                No data found. Please select the filters to refine your search.
              </Typography>
            </Box>
          )}
        </TableContainer>)}

      <Box display="flex" justifyContent="center" gap={3} sx={{ mt: 2 }}>
        <Button variant="outlined" onClick={handlePreviousPage} disabled={pageNumber === 1} >  Previous </Button>
        <Button variant="outlined" onClick={handleNextPage} disabled={!hasMore} > Next </Button>
      </Box>

      {openActiveModal === 'verification' && (
        <UserVerificationSlot open={!!openActiveModal} onClose={() => handleOpenModal(null)} userId={selectedUser?.id} phoneNumber={selectedUser?.phoneNumber} />)}
      {openActiveModal === 'bankDetails' && (<AddBankDetails open={!!openActiveModal} onClose={() => handleOpenModal(null)} userId={selectedUser?.id} />)}
      <DeleteConfirm open={isConfirmDeleteModelOpen} onClose={() => setIsConfirmDeleteModelOpen(false)} onConfirm={handleDeleteUser} />
      {succesMessage ?
        <SuccessPopup open={successPopup} onClose={closePopup} message={succesMessage} /> : ""}
    </Box>
  );
};
export default User;
