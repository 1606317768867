import React, { useContext, useEffect, useState } from 'react';
import {
    Box, FormControl, InputLabel, MenuItem, Select, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Button,
    Modal, TextField, Grid, CircularProgress, Divider
} from '@mui/material';
import axios from 'axios';
import { Context } from '../../Context/Context';
import apiUrl from '../../Config';
import { Pencil, Trash2 } from 'lucide-react';
import DeleteConfirm from '../../Common/DeleteConfirm';
import SuccessPopup from '../../Common/SuccessPopup';

const Policies = () => {
    const { token, successPopup, succesMessage,setSuccessPopup, closePopup,  setSuccesMessage,
        isConfirmDeleteModelOpen, setIsConfirmDeleteModelOpen,itemIdToDelete,setItemIdToDelete } = useContext(Context)
    const [policies, setPolicies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [policyData, setPolicyData] = useState({
        moduleId: '',
        policyName: '',
        id: ''
    });

    const handleOpenpopup = () => {
        setPolicyData({
            moduleId: '',
            policyName: '',
            id: ''
        });
        setShowPopup(true);
        setError("");
    };

    const handleClosepopup = () => {
        setShowPopup(false);
    };
    // get all modules
    const getModules = async () => {
        try {
            const response = await axios.get(apiUrl + '/rolemanager/listpolicymodules',
                {
                    headers: {
                        Accept: "*/*",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.data?.status === true) {
                setModules(response?.data?.modules);
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setError(error?.response?.data?.title)
            } else {
                setError(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    // get all policies
    const getPolicies = async () => {
        try {
            const response = await axios.get(apiUrl + '/rolemanager/getallpolicies', {

                headers: {
                    Accept: "*/*",
                    Authorization: `Bearer ${token}`,
                },

            });
            if (response?.data?.status === true) {
                setPolicies(response?.data?.policies);
            }
        } catch (err) {
            if (error?.response?.data?.status === 401) {
                setError(error?.response?.data?.title)
            } else {
                setError(error?.response?.data?.message || "Something went wrong");
            }
        } finally {
            setLoading(false);
        }
    };

    // handle edit policy
    const handleEditPolicies = async (policiesId) => {
        // setPolicyData({
        //     moduleId: policy.moduleId,
        //     policyName: policy.policyName,
        //     id: policy.id,
        // });
        // setShowPopup(true);
        try {
            const response = await axios.get(apiUrl + `/rolemanager/getpolicy?id=${policiesId}`,
                {
                    headers: {
                        Accept: "*/*",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.data?.status === true) {
                setPolicyData(response?.data?.policy);
            }
            setError("");
            setShowPopup(true)
        } catch (err) {
            if (error?.response?.data?.status === 401) {
                setError(error?.response?.data?.title)
            } else {
                setError(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    const ConfirmDeletePolicies = (policiesId) => {
        setItemIdToDelete(policiesId)
        setIsConfirmDeleteModelOpen(true);
    }

    // handle to delete policy
    const handleDeletePolicies = async () => {
        setIsConfirmDeleteModelOpen(false);
        try {
            const response = await axios.post(apiUrl + `/rolemanager/deletepolicy`, { id: itemIdToDelete },
                {
                    headers: {
                        Accept: "*/*",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.status === true) {
                setSuccesMessage("Policies deleted successfull")
                setSuccessPopup(true)
                getPolicies()
            }
            setError("");
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setError(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    const handleInputChange = (e) => {
        setPolicyData({ ...policyData, [e.target.name]: e.target.value });
        setError("")
    };

    useEffect(() => {
        getPolicies();
        getModules();
    }, []);

    // handle submit form
    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = policyData?.id
            ? `${apiUrl}/rolemanager/updatepolicy`
            : `${apiUrl}/rolemanager/createpolicy`;

        const payload = {
            id: policyData?.id,
            moduleId: policyData?.moduleId,
            policyName: policyData?.policyName,
        };

        try {
            const response = await axios.post(url, payload, {
                headers: {
                    Accept: "*/*",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.data?.status === true) {
                setSuccesMessage(policyData?.id ? "Policies updated successfully" : "Policies created successfully");
                setSuccessPopup(true);
                handleClosepopup()
                getPolicies();
            }
        } catch (error) {
            setError(error?.response?.data?.message || "Something went wrong");
        }
    };

    const filteredPolicies = selectedModule
        ? policies.filter(
            (policy) =>
                modules.find((module) => module.id === policy.moduleId)?.module === selectedModule
        )
        : policies;

    return (
        <>
            <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                    <Typography variant='h4'>List of Policies</Typography>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                        <FormControl fullWidth size='small' sx={{ width: "200px" }}>
                            <InputLabel id="modules-label">Select Policies Module</InputLabel>
                            <Select
                                labelId="modules-label"
                                id="modules"
                                value={selectedModule}
                                onChange={(e) => setSelectedModule(e.target.value)}
                                label="Select Policies Module"

                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {modules.map((module) => (
                                    <MenuItem key={module.id} value={module.module}>
                                        {module.module}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button variant="contained" sx={{ width: "200px" }} onClick={() => handleOpenpopup()}>
                            Add new policies
                        </Button>
                    </Box>
                </Box>
                <Divider />
                 {error && (
                        <Grid item xs={12}>
                          <Typography color="error">{error}</Typography>
                        </Grid>
                      )}

                {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                    <CircularProgress />
                </Box>) : (<TableContainer component={Paper} sx={{ marginTop: "20px" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="">Policy ID</TableCell>
                                <TableCell align="">Module Name</TableCell>
                                <TableCell align="">Policy Name</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredPolicies?.length > 0 ? (
                                filteredPolicies?.map((policy) => {
                                    const moduleName =
                                        modules.find((module) => module.id === policy?.moduleId)?.module ||
                                        'N/A';
                                    return (
                                        <TableRow key={policy.id}>
                                            <TableCell align="">{policy?.id}</TableCell>
                                            <TableCell align="">{moduleName}</TableCell>
                                            <TableCell align="">{policy?.policyName}</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => handleEditPolicies(policy?.id)} color="primary">
                                                    <Pencil size={18} />
                                                </IconButton>
                                                <IconButton onClick={() => ConfirmDeletePolicies(policy?.id)} color='error'sx={{padding:"0px",marginLeft:"10px"}} >
                                                    <Trash2 size={18} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography align="center" variant="body2">
                                            No data found for the selected module.
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>)}

            </Box>

            <Modal open={showPopup} onClose={handleClosepopup}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100vh" }}
                >
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "background.paper",
                                borderRadius: 2,
                                boxShadow: 24,
                                p: 4,
                                width: "400px",
                            }}
                        >
                            <Typography variant="h4" sx={{ textAlign: "center" }}>{policyData?.id ? 'Edit Policy' : 'Add New Policy'}</Typography>

                            <Box component="form" onSubmit={handleSubmit} >
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="module-label">Select Policies Module</InputLabel>
                                            <Select
                                                labelId="module-label"
                                                id="module"
                                                name="moduleId"
                                                value={policyData?.moduleId}
                                                onChange={handleInputChange}
                                                label="Select Policies Module"
                                                required
                                            >
                                                {modules.map((module) => (
                                                    <MenuItem key={module?.id} value={module?.id}>
                                                        {module?.module}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Policy Name"
                                            name="policyName"
                                            size='small'
                                            value={policyData?.policyName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Grid>
                                </Grid>

                                {error && (
                                    <Grid item xs={12}>
                                        <Typography color="error" variant="body1" align="center">
                                            {error}
                                        </Typography>
                                    </Grid>
                                )}

                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                                    <Button type="submit" variant="contained">
                                        {policyData?.id ? 'Update' : 'Create'}
                                    </Button>
                                    <Button onClick={handleClosepopup} color='error' variant="contained">Cancel</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Modal>
            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
            <DeleteConfirm
                open={isConfirmDeleteModelOpen}
                onClose={() => setIsConfirmDeleteModelOpen(false)}
                onConfirm={handleDeletePolicies}
            />
        </>
    );
};

export default Policies;