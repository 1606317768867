
import React, { useContext, useEffect, useState } from 'react';
import {
    Typography,
    Box,
    Button,
    Modal,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    Table,
    TableBody,
    Grid,
    CircularProgress,
    Divider,
} from '@mui/material';
import axios from 'axios';
import apiUrl from "../../Config";
import { Context } from '../../Context/Context';

import { Pencil, Trash2 } from 'lucide-react';
import IconButton from '@mui/material/IconButton';
import SuccessPopup from '../../Common/SuccessPopup';
import DeleteConfirm from '../../Common/DeleteConfirm';


const Languages = () => {
    const { token, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage,
        isConfirmDeleteModelOpen,
        setIsConfirmDeleteModelOpen,
        itemIdToDelete,
        setItemIdToDelete } = useContext(Context)

    const [languages, setLanguages] = useState([]);
    const [languageData, setLanguageData] = useState({
        id: 0,
        name: "",
        localName: "",
        siteTranslationAvailable: false,
        isActive: true,
        isDeleted: false,
    });
    const [modalMode, setModalMode] = useState("add");
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)

    const fetchLanguages = async () => {
        setLoading(true)
        try {
            const response = await axios.get(apiUrl + '/utilities/getlanguages', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response?.data?.status === true) {
                setLanguages(response?.data?.languages);
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }
        }finally{
            setLoading(false)
        }
    };

    const handleModalOpen = (mode, language = null) => {
        setModalMode(mode);
        if (mode === "edit" && language) {
            setLanguageData(language);
        } else {
            setLanguageData({
                id: 0,
                name: "",
                localName: "",
                siteTranslationAvailable: false,
                isActive: true,
                isDeleted: false,
            });
        }
        setEditModalOpen(true);
    };

    const handleModalClose = () => {
        setEditModalOpen(false);
    };

    const handleSave = async () => {
        try {
            if (modalMode === "edit") {

                const response = await axios.put(`${apiUrl}/utilities/updatelanguage`, languageData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
                if (response?.data?.status === true) {
                    setSuccesMessage(response?.data?.message || "Language updated successfull")
                    setSuccessPopup(true)
                }
            } else {
                const response = await axios.post(`${apiUrl}/utilities/createlanguage`, languageData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
                if (response?.data?.status === true) {
                    setSuccesMessage(response?.data?.message || "Language created successfull")
                    setSuccessPopup(true)
                }
            }
            fetchLanguages();
            handleModalClose();
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }
        }

    };

    const handleLanguageDelete = (languageID) => {
        setIsConfirmDeleteModelOpen(true)
        setItemIdToDelete(languageID)
    }

    const handleDelete = async () => {
        setIsConfirmDeleteModelOpen(false)

        const deleteFormData = {
            id: itemIdToDelete
        }

        try {
            const response = await axios.post(`${apiUrl}/utilities/deletelanguage`, deleteFormData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message || "Language deleted successfull")
                setSuccessPopup(true)
                fetchLanguages();
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }

        }
    };

    useEffect(() => {
        fetchLanguages();
    }, []);

    return (
        <>
            <Box mt={8} p={3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4">Languages</Typography>
                    <Button variant="contained" onClick={() => handleModalOpen("add")}>
                        Add Language
                    </Button>
                </Box>

                <Divider sx={{  mb: 3, mt: 3 }} />

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Local Name</TableCell>
                                <TableCell>Site Translation</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell align='right'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {languages?.map((language, index) => (
                                <TableRow key={index}>
                                    <TableCell>{language?.name}</TableCell>
                                    <TableCell>{language?.localName}</TableCell>
                                    <TableCell>{language?.siteTranslationAvailable ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{language?.isActive ? 'Yes' : 'No'}</TableCell>
                                    <TableCell align='right'>
                                        <IconButton
                                            color='primary'
                                            onClick={() => handleModalOpen("edit", language)}
                                            sx={{ marginRight: 1 }}
                                        >
                                            <Pencil size={18} />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleLanguageDelete(language?.id)}
                                        >
                                            <Trash2 size={18} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                )}
                

                <Modal
                    open={editModalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="edit-language-modal"
                    aria-describedby="edit-language-form"
                >
                    <Grid container justifyContent='center' alignItems='center' height='100vh'>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 2,
                            }}
                        >
                            <Typography id="edit-language-modal" variant="h6" mb={2}>
                                {modalMode === "edit" ? "Edit Language" : "Add Language"}
                            </Typography>
                            <TextField
                                fullWidth
                                label="Name"
                                size='small'
                                value={languageData.name}
                                onChange={(e) =>
                                    setLanguageData({ ...languageData, name: e.target.value })
                                }
                                sx={{ mb: 2 }}
                                required
                            />
                            <TextField
                                fullWidth
                                size='small'
                                label="Local Name"
                                value={languageData.localName}
                                onChange={(e) =>
                                    setLanguageData({ ...languageData, localName: e.target.value })
                                }
                                sx={{ mb: 2 }}
                                required
                            />
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Site Translation</InputLabel>
                                <Select
                                label="Site Translation"
                                    value={languageData.siteTranslationAvailable ? "Yes" : "No"}
                                    size='small'
                                    onChange={(e) =>
                                        setLanguageData({
                                            ...languageData,
                                            siteTranslationAvailable: e.target.value === "Yes",
                                        })
                                    }
                                    required
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Active</InputLabel>
                                <Select
                                    value={languageData.isActive ? "Yes" : "No"}
                                    size='small'
                                    label='Active'
                                    onChange={(e) =>
                                        setLanguageData({
                                            ...languageData,
                                            isActive: e.target.value === "Yes",
                                        })
                                    }
                                    required
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                <Button variant="contained" color='error' onClick={handleModalClose}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleSave}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Modal>
            </Box>

            <DeleteConfirm
                open={isConfirmDeleteModelOpen}
                onClose={() => setIsConfirmDeleteModelOpen(false)}
                onConfirm={handleDelete}

            />

            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </>

    );
};

export default Languages;

