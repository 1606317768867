import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Avatar, Box, Button, Checkbox, Dialog, Divider, FormControl, FormControlLabel, Grid, InputLabel,
  ListItemText, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField, Typography,
} from "@mui/material";
import { Context } from "../../Context/Context";
import apiUrl from "../../Config";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessPopup from '../../Common/SuccessPopup';
import { CircleX, } from "lucide-react";
const AddUser = () => {
  const { roles, serviceTypes, zones, languages, token, successPopup,
    succesMessage, setSuccessPopup, closePopup, setSuccesMessage, } = useContext(Context);
  const [subservices, setSubservices] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedService, setSelectedService] = useState([]);
  const [isDeletedBySubservice, setIsDeletedBySubservice] = useState({});
  const [selectedSubservices, setSelectedSubservices] = useState([]);
  const [isCertifiedBySubservice, setIsCertifiedBySubservice] = useState({});
  const [skills, setSkills] = useState([]);
  const [states, setStates] = useState([])
  const [errorMessages, setErrorMessages] = useState([])
  const location = useLocation();
  const UserId = location?.state?.id;
  const [addresses, setAddresses] = useState([]);
  const [language, setLanguages] = useState([])
  const [languageData, setLanguageData] = useState([]);
  const navigate = useNavigate()
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    birthDate: "",
    gender: "",
    prefferredLanguage: "",
    roles: [],
    isActive: false,
    isKYCVerified: false,
    aadharNumber: "",
    panNumber: ""
  });
  const [fileData, setFileData] = useState({
    files: {},
    filePaths: {},
    fileIds: {},
  });


  const handleFileChange = (event, fileType) => {
    const selectedFile = event.target.files[0];
    setFileData((prev) => ({
      ...prev,
      files: { ...prev.files, [fileType]: selectedFile },
      filePaths: { ...prev.filePaths, [fileType]: selectedFile ? URL.createObjectURL(selectedFile) : null },
    }));
  };


  const handleClickChooseImage = (fileType) => {
    document.getElementById(fileType).click();
  };

  const fileInputRefs = {
    Aadhar: useRef(null),
    ProfileImage: useRef(null),
    PANCard: useRef(null),
    SkillCertificates: useRef(null),
  };

  const [imageOpen, setImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");



  const handleClickOpen = (filePath) => {
    setSelectedImage(filePath);

    setImageOpen(true);
  };

  const handleClose = () => {
    setImageOpen(false);
    setSelectedImage("");
  };

  // get subservices based on serviceId and zoneId
  const getSubservices = async (serviceId, zoneId) => {
    if (serviceId && zoneId) {
      try {
        const response = await axios.get(
          apiUrl + `/servicetypes/0/getservicesubtypesbyserviceidwithzoneid?serviceId=${serviceId}&zoneId=${zoneId}`
        );
        if (response.data.status === true) {
          setSubservices(response.data.subservices);
        }
      } catch (error) {
        if (error?.response?.data?.status === 401) {
          setErrorMessages(error?.response?.data?.title)
        } else {
          setErrorMessages(error?.response?.data?.message || "Something went wrong");
        }
      }
    }
  };

  const handleSubservicesChange = (subserviceId) => {
    setSelectedSubservices((prev) =>
      prev.includes(subserviceId)
        ? prev.filter((id) => id !== subserviceId)
        : [...prev, subserviceId]
    );
  };

  const handleDeleteToggle = (subserviceId) => {
    setIsDeletedBySubservice((prevState) => ({
      ...prevState,
      [subserviceId]: !prevState[subserviceId],
    }));
  };

  // Handle certification toggle for each subservice
  const handleCertificationChange = (subserviceId) => {
    setIsCertifiedBySubservice((prev) => ({
      ...prev,
      [subserviceId]: !prev[subserviceId],
    }));
  };

  // Handle zone change
  const handleZoneChange = (event) => {
    const zoneId = event.target.value;
    setSelectedZone(zoneId);
    if (zoneId && selectedService) {
      getSubservices(selectedService, zoneId);
    }
  };

  // Handle service change
  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    setSelectedService(serviceId);
    if (serviceId && selectedZone) {
      getSubservices(serviceId, selectedZone);
    }
  };


  const formattedBirthDate = userData?.birthDate
    ? new Date(userData.birthDate).toISOString().split("T")[0]
    : "";


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : name === "panNumber" ? value.toUpperCase() : value;
    setUserData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setErrorMessages("");
  };


  const getStates = async () => {
    try {
      const response = await axios.get(apiUrl + `/utilities/getstates`);
      if (response.data.status === true) {
        setStates(response?.data?.state);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setErrorMessages(error?.response?.data?.title)
      } else {
        setErrorMessages(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  // password validation
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNonAlphanumeric = /[^A-Za-z0-9]/.test(password);

    return password?.length >= minLength && hasUpperCase && hasNonAlphanumeric;
  };

  // address change
  const handleAddressChange = (index, event) => {
    const newAddresses = [...addresses];
    newAddresses[index][event.target.name] = event.target.value;
    setAddresses(newAddresses);
  };

  // add new address function
  const addAddress = () => {
    setAddresses((prevAddresses) => [
      ...(Array.isArray(prevAddresses) ? prevAddresses : []),
      {
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Pincode: "",
        Landmark: "",
        AddressType: "",
        IsDeleted: false,
      },
    ]);
  };

  //address types
  const addressTypes = [
    { id: 1, name: "Present" },
    { id: 2, name: "UserAddress" },
    { id: 3, name: "Office" },
    { id: 4, name: "Permanent" },
    { id: 5, name: "Home" },
  ];

  // remove perticular address
  const removeAddress = (index) => {
    const newAddresses = [...addresses];
    newAddresses.splice(index, 1);
    setAddresses(newAddresses);
  };
  const languageChange = (event) => {
    setLanguages(event.target.value);
  };

  const selectedNames = languages.filter(option => language.includes(option.id)).map(option => option.name);
  const getUserById = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/users/${id}`);
      if (response?.data?.status === true) {
        setUserData({
          ...response?.data?.userdetails,
          roles: response?.data?.roles,
        });
        const fetchedLanguages = response?.data?.languages || [];
        setLanguageData(fetchedLanguages)
        setLanguages(fetchedLanguages.map(lang => lang?.language_Id))

        setAddresses(response?.data?.addresses);
        setSkills(response?.data?.skills);
        setSelectedZone(response?.data?.userdetails?.defaultZoneId);
        setSelectedService(response?.data?.skills[0]?.serviceID || response?.data?.userdetails?.defaultServiceId
        );
        const existingSubservices = response?.data?.skills?.map(
          (skill) => skill?.subServiceID
        );
        setSelectedSubservices(existingSubservices);
        // Set certification statuses for existing subservices
        const certificationStatus = response?.data?.skills?.reduce(
          (acc, skill) => {
            acc[skill.subServiceID] = skill?.isCertified;
            return acc;
          },
          {}
        );
        setIsCertifiedBySubservice(certificationStatus);
        const apiFiles = response.data.files;
        const newFiles = {};
        const newFilePaths = {};
        const newFileIds = {};

        apiFiles.forEach((file) => {
          const fileType = getFileTypeFromId(file?.fileType_Id);
          if (fileType) {
            newFiles[fileType] = null;
            newFilePaths[fileType] = apiUrl + "/" + file?.filePath;
            newFileIds[fileType] = file?.id;
          }
        });
        setFileData({
          files: newFiles,
          filePaths: newFilePaths,
          fileIds: newFileIds,
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setErrorMessages(error?.response?.data?.title)
      } else {
        setErrorMessages(error?.response?.data?.message || "Something went wrong");
      }
    }
  };


  const getFileTypeFromId = (fileTypeId) => {
    const fileTypeMapping = {
      1: 'Aadhar',
      2: 'ProfileImage',
      3: 'PANCard',
      7: 'SkillCertificates'
    };
    return fileTypeMapping[fileTypeId] || null;
  };

  const fileTypes = ['Aadhar', 'ProfileImage', 'PANCard', 'SkillCertificates'];

  useEffect(() => {
    if (userData?.roles?.includes("Worker")) {
      getSubservices(selectedService, selectedZone);
    }
  }, [selectedService, selectedZone]);

  useEffect(() => {
    getStates()
    getUserById(UserId);
  }, []);

  // form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const userFormData = new FormData();
    userFormData.append("Email", userData?.email);
    userFormData.append("FirstName", userData?.firstName);
    userFormData.append("LastName", userData?.lastName);
    userFormData.append("PhoneNumber", userData?.phoneNumber);
    userFormData.append("Password", userData?.password);
    userFormData.append("DateOfBirth", userData?.birthDate);
    userFormData.append("Gender", userData?.gender);
    userFormData.append("prefferedLanguage", userData?.prefferredLanguage);
    userFormData.append("Role", userData?.roles);
    userFormData.append("DefaultZoneId", selectedZone || 0);
    userFormData.append("DefaultServiceId", selectedService || 0);
    addresses?.forEach((address, index) => {
      if (address.id) {
        userFormData.append(`Addresses[${index}].id`, address?.id);
        userFormData.append(
          `Addresses[${index}].IsDeleted`,
          address?.IsDeleted || false
        );
      }
      userFormData.append(`Addresses[${index}].Address1`, address?.address1);
      userFormData.append(`Addresses[${index}].Address2`, address?.address2);
      userFormData.append(`Addresses[${index}].City`, address?.city);
      userFormData.append(`Addresses[${index}].State`, address?.state);
      userFormData.append(`Addresses[${index}].Pincode`, address?.pincode);
      userFormData.append(`Addresses[${index}].Landmark`, address?.landMark);

      userFormData.append(
        `Addresses[${index}].AddressType`,
        address?.addressType_Id
      );
    });
    selectedSubservices.forEach((subserviceId, index) => {
      const existingSkill = skills?.find(
        (skill) => skill?.subServiceID === subserviceId
      );

      if (existingSkill?.id) {
        userFormData.append(`Skills[${index}].id`, existingSkill?.id);
        userFormData.append(
          `Skills[${index}].isDeleted`,
          isDeletedBySubservice[subserviceId] || false
        );
      }
      userFormData.append(`Skills[${index}].SubServiceID`, subserviceId);
      userFormData.append(
        `Skills[${index}].isCertified`,
        isCertifiedBySubservice[subserviceId] || false
      );
    });
    Object.keys(fileData.files).forEach((fileType, index) => {
      userFormData.append(
        `Files[${index}].FileDetails`,
        fileData?.files[fileType] || fileData?.filePaths[fileType]
      );
      userFormData.append(`Files[${index}].FileType`, fileType);
      if (fileData?.fileIds[fileType]) {
        userFormData.append(`Files[${index}].id`, fileData?.fileIds[fileType]);
      }
    })
    try {
      if (UserId) {
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        const isPanValid = panRegex.test(userData?.panNumber);
        if (!isPanValid) {
          setErrorMessages("Pan card number is invalid");
          return;
        }
        userFormData.append("id", UserId);
        userFormData.append("isActive", userData?.isActive);
        userFormData.append("isKYCVerified", userData?.isKYCVerified || false);
        userFormData.append("PANNumber", userData?.panNumber);
        userFormData.append("AadharNumber", userData?.aadharNumber);
        language.forEach((id, index) => {
          const existingLanguage = languageData?.find((lang) => lang?.language_Id === id) || {};
          userFormData.append(`Languages[${index}].id`, existingLanguage.id || 0);
          userFormData.append(`Languages[${index}].Language_Id`, id);
          userFormData.append(`Languages[${index}].CanRead`, true);
          userFormData.append(`Languages[${index}].CanWrite`, true);
          userFormData.append(`Languages[${index}].CanSpeak`, true);
        });
        const response = await axios.post(
          apiUrl + "/users/update",
          userFormData,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201) {
          setSuccesMessage("User Updated Successfull");
          setSuccessPopup(true)
          setTimeout(() =>
            getUserById(UserId)
            , navigate(-1),
            3000);
        }
      } else {
        if (!validatePassword(userData?.password)) {
          setErrorMessages([
            "Password must be at least 8 characters, contain at least one uppercase letter, and one non-alphanumeric character.",
          ]);
          return;
        }
        const response = await axios.post(
          apiUrl + "/users/register",
          userFormData,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 201) {
          setSuccesMessage("User Created Successfull")
          setSuccessPopup(true)
          setTimeout(() => getUserById(UserId),
            navigate(-1), 3000);
        }
      }
      setErrorMessages([]);
      setUserData({});
    } catch (error) {
      console.log(error.response, "error"
      )
      const errorResponse = error.response?.data;
      if (errorResponse?.InvalidEmail?.[0]) {
        setErrorMessages(errorResponse?.InvalidEmail[0]);
      } else if (errorResponse?.DuplicateEmail?.[0]) {
        setErrorMessages(errorResponse.DuplicateEmail[0]);
      } else if (errorResponse?.DuplicateUserName?.[0]) {
        setErrorMessages(errorResponse?.DuplicateUserName[0]);
      } else if (errorResponse?.message) {
        setErrorMessages(errorResponse?.message);
      }
      else
        if (error?.response?.data?.status === 401) {
          setSuccesMessage(error?.response?.data?.title)
          setSuccessPopup(true)
        } else if (error?.response?.status === 500) {
          setErrorMessages(error?.response?.message || "Something went wrong. Please try again later.");
        } else {
          setErrorMessages("Fill the all  details");
        }
    }
  };

  return (
    <>
      <Box sx={{ mt: "80px" }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h5">Personal Details</Typography>
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <Button
                    type="submit"
                    variant="contained"
                  >
                    {UserId ? "Update" : "Register"}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="error"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>

            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                name="firstName"
                size="small"
                label="First Name"
                variant="outlined"
                fullWidth
                required
                value={userData?.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="lastName"
                size="small"
                label="Last Name"
                variant="outlined"
                fullWidth
                value={userData?.lastName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="email"
                label="Email"
                size="small"
                variant="outlined"
                autoComplete="username"
                fullWidth
                value={userData?.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="phoneNumber"
                size="small"
                label="Phone Number"
                variant="outlined"
                required
                type="text"
                fullWidth
                value={userData?.phoneNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d{0,10}$/.test(input)) {
                    handleChange(e);
                  }
                }}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="password"
                size="small"
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                autoComplete="current-password"
                value={userData?.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Date of Birth"
                name="birthDate"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                value={formattedBirthDate}
                onChange={handleChange}
                fullWidth
                inputProps={{
                  max: new Date().toISOString().split('T')[0],
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                size="small"
                select
                label="Gender"
                value={userData?.gender}
                name="gender"
                variant="outlined"
                onChange={handleChange}
                required
              >
                <MenuItem value={0}>Male</MenuItem>
                <MenuItem value={1}>Female</MenuItem>
                <MenuItem value={2}>Others</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Role</InputLabel>
                <Select
                  name="roles"
                  value={userData?.roles}
                  onChange={handleChange}
                  label="Role"
                  required
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Prefferred Language</InputLabel>
                <Select
                  name="prefferredLanguage"
                  value={userData?.prefferredLanguage}
                  onChange={handleChange}
                  label="Prefferred Language"
                >
                  {languages.map((languages) => (
                    <MenuItem key={languages.id} value={languages.id}>
                      {languages.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {UserId && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="panNumber"
                    label="PAN Number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={userData?.panNumber}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 10,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="aadharNumber"
                    size="small"
                    label="Aadhar Number"
                    variant="outlined"
                    required
                    type="number"
                    fullWidth
                    value={userData?.aadharNumber}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d{0,12}$/.test(input)) {
                        handleChange(e);
                      }
                    }}
                    inputProps={{
                      maxLength: 12,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Known Languages</InputLabel>
                    <Select
                      multiple
                      value={language}
                      label="Known Languages"
                      onChange={languageChange}
                      renderValue={() => selectedNames.join(', ')}
                    >
                      {languages.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox checked={language.indexOf(option.id) > -1} />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userData.isActive || false}
                        onChange={handleChange}
                        name="isActive"
                        color="primary"
                      />
                    }
                    label="Is Active"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userData?.isKYCVerified}
                        onChange={handleChange}
                        name="isKYCVerified"
                        color="primary"
                      />
                    }
                    label="Is KYC Verified"
                  />
                </Grid>
              </>
            )}

            {/* Zone Dropdown */}
            {userData?.roles?.includes("Worker") && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Skills</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="zone-select-label">Select Zone</InputLabel>
                    <Select
                      labelId="zone-select-label"
                      id="zone-select"
                      value={selectedZone}
                      onChange={handleZoneChange}
                      label="Select Zone"

                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {zones.map((zone) => (
                        <MenuItem key={zone.id} value={zone.id}>
                          {zone.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Service Dropdown */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="service-select-label">
                      Select Service
                    </InputLabel>
                    <Select
                      labelId="service-select-label"
                      id="service-select"
                      value={selectedService}
                      onChange={handleServiceChange}
                      label="Select Service"

                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {serviceTypes.map((service) => (
                        <MenuItem key={service.id} value={service.id}>
                          {service.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Subservices Multi-Select Dropdown with Certification Toggle */}
                <Grid item xs={12}>
                  <Typography variant="h6">Select Subservices</Typography>
                </Grid>
                {/* Loop through subservices */}

                <Grid item xs={12}>
                  {subservices && subservices.length > 0 ? (
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                      <Table>
                        {/* Table Header */}
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h6">Name</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="h6">Certified</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="h6">Is deactive</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                          {subservices.map((subservice) => (
                            <TableRow key={subservice.id}>
                              {/* Subservice Name */}
                              <TableCell>
                                <Checkbox
                                  checked={selectedSubservices.includes(
                                    subservice.id
                                  )}
                                  onChange={() =>
                                    handleSubservicesChange(subservice.id)
                                  }
                                />
                                {subservice.name}
                              </TableCell>

                              {/* Certified Column */}
                              <TableCell align="center">
                                <Switch
                                  checked={
                                    isCertifiedBySubservice[subservice.id] || false
                                  }
                                  onChange={() =>
                                    handleCertificationChange(subservice.id)
                                  }
                                />
                              </TableCell>

                              {/* Is Delete Column */}
                              <TableCell align="center">
                                <Switch
                                  checked={
                                    isDeletedBySubservice[subservice.id] || false
                                  }
                                  onChange={() => handleDeleteToggle(subservice.id)}
                                  color="error"
                                  inputProps={{
                                    "aria-label": `deleted-${subservice.id}`,
                                  }}
                                  sx={{ marginLeft: 2 }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6" textAlign="center">
                          Subservice not available
                        </Typography>
                      </Grid>
                    </>
                  )}


                </Grid>

                {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>

                  <Button variant="contained"
                    color="primary" onClick={() => handleOpenWeeklyRoutines()}>WeeklyRoutines</Button>
                </Grid> */}
              </>)}

            <Grid item xs={12}>
              <Typography variant="h5">Addresses</Typography>
            </Grid>
            {/* Addresses Section */}
            {addresses.length > 0 ? (
              addresses.map((address, index) => (
                <Grid item xs={12} key={`address-${index}`}>
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      padding: 2,
                      marginBottom: 2,
                      borderRadius: "8px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, }}
                      >
                        <Typography
                          variant="h6" sx={{ display: "flex", alignItems: "center" }}  > {`Address ${index + 1}`}
                        </Typography>
                        <Box
                          sx={{ display: "flex", justifyContent: "end", gap: "10px" }}>
                          {index >= 0 && (
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => removeAddress(index)}
                            >
                              Remove Address
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={addAddress}
                          >
                            Add Address
                          </Button>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          label="Address 1"
                          name="address1"
                          aria-label="address Line 1"
                          value={address.address1}
                          onChange={(e) => handleAddressChange(index, e)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          label="Address 2"
                          name="address2"
                          aria-label="Address Line 2"
                          value={address.address2}
                          onChange={(e) => handleAddressChange(index, e)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          label="City"
                          name="city"
                          aria-label="City"
                          value={address.city}
                          onChange={(e) => handleAddressChange(index, e)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth size="small">
                          <InputLabel>State</InputLabel>
                          <Select
                            name="state"
                            value={address.state}
                            onChange={(e) => handleAddressChange(index, e)}
                            label="State"
                          >
                            {states?.map((state) => (
                              <MenuItem key={state.id} value={state.id}>
                                {state.stateName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          label="Pincode"
                          name="pincode"
                          aria-label="Pincode"
                          value={address.pincode}
                          onChange={(e) => handleAddressChange(index, e)}
                          fullWidth
                          inputProps={{
                            pattern: "[0-9]{6}",
                            maxLength: 6,
                          }}

                          error={
                            address.pincode && !/^\d{6}$/.test(address.pincode)
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          label="Landmark"
                          name="landMark"
                          aria-label="Landmark"
                          value={address.landMark}
                          onChange={(e) => handleAddressChange(index, e)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Address Type</InputLabel>
                          <Select
                            name="addressType_Id"
                            size="small"
                            value={address.addressType_Id}
                            onChange={(e) => handleAddressChange(index, e)}
                            label="Address Type"
                          >
                            {addressTypes.map((type) => (
                              <MenuItem key={type.id} value={type.id}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Deleted Button */}
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={address.IsDeleted}
                              onChange={(e) => {
                                const newAddresses = [...addresses];
                                newAddresses[index].IsDeleted =
                                  e.target.checked;
                                setAddresses(newAddresses);
                              }}
                            />
                          }
                          label="Mark as Deleted"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: "end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addAddress}
                >
                  Add Address
                </Button>
              </Grid>
            )}

            {/* File Uploads Section */}
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom color="">
                Upload Files
              </Typography>
            </Grid>
            {/* {fileTypes.map((fileType) => (
              <>
                <Grid item xs={3}>
                  {fileData.filePaths[fileType] && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        alt={`${fileType} preview`}
                        src={fileData.filePaths[fileType]}
                        sx={{
                          width: 150,
                          height: 150,
                          border: '2px solid #ccc',
                          objectFit: 'contain',
                          cursor: "pointer"
                        }}
                        onClick={() => handleClickOpen(fileData.filePaths[fileType])}
                      />
                    </Box>
                  )}
                  <Dialog
                    open={imageOpen}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                    sx={{
                      "& .MuiDialog-paper": {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        display: "flex",

                      },
                    }}
                  >
                    <Box sx={{
                      display: "flex", justifyContent: "end", marginRight: "120px",
                      color: "#FFFFFF",
                    }}>
                      <Button onClick={handleClose} aria-label="close" sx={{
                        display: "flex",
                        color: "#FFFFFF",
                      }}>
                        <CircleX />
                      </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {selectedImage && (
                        <img
                          src={
                            selectedImage
                              ? selectedImage
                              : selectedImage instanceof File
                                ? URL.createObjectURL(selectedImage)
                                : ""
                          }
                          alt="Preview"
                          style={{ width: "50%", objectFit: "contain", }}
                        />
                      )}
                    </Box>
                  </Dialog>
                  <input
                    id={fileType}
                    type="file"
                    hidden
                    onChange={(event) => handleFileChange(event, fileType)}
                    accept=".png,.jpg,.jpeg,.pdf"
                    ref={fileInputRefs[fileType.name]}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickChooseImage(fileType)}
                    sx={{ marginTop: 1 }}
                    fullWidth
                  >
                    Upload {fileType}
                  </Button>
                </Grid>
              </>
            ))} */}

            {fileTypes.map((fileType) => (
              <>
                {(fileType !== 'SkillCertificate' || UserId) && ( // Check if userId exists for SkillCertificate
                  <Grid item xs={3}>
                    {fileData.filePaths[fileType] && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          alt={`${fileType} preview`}
                          src={fileData.filePaths[fileType]}
                          sx={{
                            width: 150,
                            height: 150,
                            border: '2px solid #ccc',
                            objectFit: 'contain',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleClickOpen(fileData.filePaths[fileType])}
                        />
                      </Box>
                    )}
                    <Dialog
                      open={imageOpen}
                      onClose={handleClose}
                      fullWidth
                      maxWidth="md"
                      sx={{
                        '& .MuiDialog-paper': {
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          display: 'flex',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          marginRight: '120px',
                          color: '#FFFFFF',
                        }}
                      >
                        <Button
                          onClick={handleClose}
                          aria-label="close"
                          sx={{
                            display: 'flex',
                            color: '#FFFFFF',
                          }}
                        >
                          <CircleX />
                        </Button>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {selectedImage && (
                          <img
                            src={
                              selectedImage
                                ? selectedImage
                                : selectedImage instanceof File
                                  ? URL.createObjectURL(selectedImage)
                                  : ''
                            }
                            alt="Preview"
                            style={{ width: '50%', objectFit: 'contain' }}
                          />
                        )}
                      </Box>
                    </Dialog>
                    <input
                      id={fileType}
                      type="file"
                      hidden
                      onChange={(event) => handleFileChange(event, fileType)}
                      accept=".png,.jpg,.jpeg,.pdf"
                      ref={fileInputRefs[fileType]}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickChooseImage(fileType)}
                      sx={{ marginTop: 1 }}
                      fullWidth
                    >
                      Upload {fileType}
                    </Button>
                  </Grid>
                )}
              </>
            ))}

            {/* Error Messages */}
            <Grid item xs={12}>
              <Box sx={{ color: "red" }}>
                {errorMessages && (
                  <Typography color="red">{errorMessages}</Typography>
                )}
              </Box>
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ padding: "8px 40px" }}
                >
                  {UserId ? "Update" : "Register"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}
    </>
  );
};
export default AddUser;