import React, { useContext, useEffect, useState } from "react";
import {
    Box, Typography, Grid,
    Paper, Button, Card, CardContent, IconButton, Link, ToggleButtonGroup, ToggleButton, Stack, Divider,
    TextField,
} from "@mui/material";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
    CategoryScale, LinearScale, LineElement, BarElement, PointElement,
} from "chart.js";
import { Mail, DollarSign, Users, CreditCard, Calendar, Upload, } from "lucide-react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Context } from "../../Context/Context";
import axios from "axios";
import apiUrl from "../../Config";
import dayjs from "dayjs";

ChartJS.register(
    ArcElement, Tooltip, Legend, CategoryScale, LinearScale, LineElement, BarElement, PointElement
);



const Dashboard = () => {
    const { token } = useContext(Context)
    const userName = localStorage.getItem('userName')
    const [dashboardDetails, setDashboardDetails] = useState("")
    const [lastWeekRegistrations1, setLastWeekRegistrations] = useState('')
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");


    const [error, setError] = useState({ fromDate: "", toDate: "" });

    useEffect(() => {
        const firstDay = dayjs().startOf("month").format("YYYY-MM-DD");
        const lastDay = dayjs().endOf("month").format("YYYY-MM-DD");
        setFromDate(firstDay);
        setToDate(lastDay);
      }, []);

    const handleFromDateChange = (event) => {
        const newFromDate = event.target.value;
        setFromDate(newFromDate);

        // Clear To Date error when From Date changes
        if (toDate && newFromDate > toDate) {
            setError((prev) => ({ ...prev, toDate: "To Date cannot be earlier than From Date" }));
        } else {
            setError((prev) => ({ ...prev, fromDate: "", toDate: "" }));
        }
    };

    const handleToDateChange = (event) => {
        const newToDate = event.target.value;
        setToDate(newToDate);

        // Validation for To Date
        if (fromDate && newToDate < fromDate) {
            setError((prev) => ({ ...prev, toDate: "To Date cannot be earlier than From Date" }));
        } else {
            setError((prev) => ({ ...prev, toDate: "" }));
        }
    };
    //   get the dashboard details in apis
    const getDashBoardData = async () => {
        try {
            const response = await axios.get(apiUrl+`/dashboard/getdashboard?fromDate=${fromDate}&toDate=${toDate}`, {
                headers: {
                    Accept: "*/*",
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response?.data?.status === true) {
                setDashboardDetails(response?.data?.details)
                setLastWeekRegistrations(response?.data?.details?.lastWeekRegistrations)
            }

        } catch (error) {
            setError(error?.response?.data?.error?.title)
        }
    }


    // no of registration user day wise lables
    const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // to combain the  last week data with labels
    const lastWeekDataMap = Object.values(lastWeekRegistrations1 || []).reduce((acc, item) => {
        acc[item.weekday] = item.registrations;
        return acc;
    }, {});

    // set
    const lastWeekData = weekdays.map(day => lastWeekDataMap[day] || 0);

    // to combain the  this week data with labels
    const thisWeekDataMap = Object.values(dashboardDetails?.previousLastWeekRegistrations || [])?.reduce((acc, item) => {
        acc[item.weekday] = item.registrations;
        return acc;
    }, {});
    const thisWeekData = weekdays.map(day => thisWeekDataMap[day] || 0);

    // set the  this week data and last week data in bar graph
    const barData = {
        labels: weekdays,
        datasets: [
            {
                label: "This Week",
                data: lastWeekData,
                backgroundColor: "rgba(54, 162, 235, 0.5)",
            },
            {
                label: "Last Week",
                data: thisWeekData,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };


    //  bookings  Statistics data
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const deliveredbookingDataMap = Object.values(dashboardDetails?.deliveredBookings || [])?.reduce((acc, item) => {
        acc[item.month] = item.bookingCount;
        return acc;
    }, {});

    const deliveredbookingData = months.map(month => deliveredbookingDataMap[month] || 0);

    const cancelledBookingsDataMap = Object.values(dashboardDetails?.cancelledBookings || [])?.reduce((acc, item) => {
        acc[item.month] = item.bookingCount;
        return acc;
    }, {});

    const cancelledBookingsData = months.map(month => cancelledBookingsDataMap[month] || 0);
    const lineData = {
        labels: months,
        datasets: [
            {
                label: "Delivered",
                data: deliveredbookingData,
                borderColor: "rgba(54, 162, 235, 1)",
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                fill: true,
            },
            {
                label: "Cancelled",
                data: cancelledBookingsData,
                borderColor: "rgba(255, 99, 132, 1)",
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                fill: true,
            },
        ],
    };


    // top booking services data
    const donutData = {
        labels: dashboardDetails?.currentDatesServiceCounts?.map(item => item?.serviceName),
        datasets: [
            {
                data: dashboardDetails?.currentDatesServiceCounts?.map(item => item?.bookingCount),
                backgroundColor: dashboardDetails?.currentDatesServiceCounts?.map((_, index) => {

                    const colorArray = ["#8b5cf6", "#f87171", "#34d399", "#60a5fa"];
                    return colorArray[index % colorArray.length];
                }),
                hoverBackgroundColor: dashboardDetails?.currentDatesServiceCounts?.map((_, index) => {
                    const hoverColorArray = ["#6b21a8", "#b91c1c", "#059669", "#2563eb"];
                    return hoverColorArray[index % hoverColorArray.length];
                }),
                borderWidth: 0,
            },
        ],
    };

    // Top booking services data in defference of months
    const serviceDifference = dashboardDetails?.currentDatesServiceCounts?.map(current => {
        const previous = dashboardDetails?.previousGapDatesServiceCounts?.find(prev => prev?.serviceName === current?.serviceName);
        const bookingDifference = current?.bookingCount - (previous ? previous?.bookingCount : 0);
        const increase = bookingDifference > 0;
        return {
            name: current?.serviceName,
            bookings: current?.bookingCount,
            change: bookingDifference,
            increase: increase
        };
    });




    useEffect(() => {
        getDashBoardData()
    }, [fromDate,toDate])

    return (
        <Box sx={{ p: 3, minHeight: "100vh" }}>
            {/* Header */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Typography variant="h6">welcome</Typography>
                    <Typography variant="h5" color="textSecondary">
                        {userName}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2
                        }}
                    >

                        <TextField
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            size="small"
                            error={Boolean(error?.fromDate)}
                            helperText={error?.fromDate}
                            inputProps={{ max: dayjs().format("YYYY-MM-DD") }}
                        />
                        <TextField
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={handleToDateChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            size="small"
                            error={Boolean(error?.toDate)}
                            helperText={error?.toDate}
                         
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Upload size={20} />}
                        sx={{ textTransform: "none" }}
                    >
                        Export Report
                    </Button>
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ borderRadius: 2, border: "1px solid rgba(0, 0, 0, 0.12)", boxShadow: "none" }}>
                        <CardContent>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total Bookings
                                </Typography>
                            </Box>
                            <Typography variant="h5" sx={{ mt: 2 }}>
                                {dashboardDetails?.totalBookings}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ borderRadius: 2, border: "1px solid rgba(0, 0, 0, 0.12)", boxShadow: "none" }}>
                        <CardContent>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total Users
                                </Typography>
                            </Box>
                            <Typography variant="h5" sx={{ mt: 2 }}>
                                {dashboardDetails?.totalUsers}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ borderRadius: 2, border: "1px solid rgba(0, 0, 0, 0.12)", boxShadow: "none" }}>
                        <CardContent>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total Professionals

                                </Typography>
                            </Box>
                            <Typography variant="h5" sx={{ mt: 2 }}>
                                {dashboardDetails?.totalProfessionals
                                }
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ borderRadius: 2, border: "1px solid rgba(0, 0, 0, 0.12)", boxShadow: "none" }}>
                        <CardContent>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total Services
                                </Typography>
                            </Box>
                            <Typography variant="h5" sx={{ mt: 2 }}>
                                {dashboardDetails?.totalServices}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>

            {/* Charts Section */}
            <Grid container spacing={2} sx={{ mt: "3px" }}>
                <Grid item xs={12} md={6} >
                    <Paper elevation={3} sx={{ p: 2, boxShadow: 'none', border: '1px solid', borderColor: "rgba(0, 0, 0, 0.12);", }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                            Number of Register Users
                        </Typography>
                        <Bar data={barData} options={{ maintainAspectRatio: true }} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 3, boxShadow: 'none', border: '1px solid ', borderColor: "rgba(0, 0, 0, 0.12);", }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                Booking Statistics
                            </Typography>
                        </Box>
                        <Line data={lineData} options={{ maintainAspectRatio: true }} />
                    </Paper>
                </Grid>
            </Grid>

            {/* Top Selling Categories Section */}
            <Box sx={{ mt: 3 }}>
                <Paper sx={{ p: 3, border: '1px solid #e0e0e0', boxShadow: 'none' }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        Top Booked Services
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                        <Box width={250} height={250} position="relative">
                            <Doughnut data={donutData} options={{ cutout: "70%", plugins: { legend: { display: false } } }} />
                            <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                style={{ transform: "translate(-50%, -50%)", textAlign: "center" }}
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    Total Bookings
                                </Typography>
                                <Typography variant="h4" fontWeight="bold">
                                    {dashboardDetails?.totalBookings}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 3 }} />
                    <Stack spacing={1} divider={<Divider flexItem />}>
                        {serviceDifference?.map((category) => (
                            <Box
                                key={category?.name}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ py: 0.5 }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: "50%",
                                            backgroundColor: category?.color,
                                            mr: 0.5,
                                            mb: 2.5
                                        }}
                                    ></Box>
                                    <Box>
                                        <Typography variant="subtitle2" fontWeight="medium">
                                            {category?.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color={category?.increase ? "green" : "red"}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            {category?.increase ? (
                                                <ArrowUpwardIcon fontSize="small" />
                                            ) : (
                                                <ArrowDownwardIcon fontSize="small" />
                                            )}
                                            {Math.abs(category?.change)}%
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="textSecondary"
                                    sx={{ mt: 1 }}
                                >
                                    {category?.bookings} Bookings
                                </Typography>
                            </Box>
                        ))}
                    </Stack>
                </Paper>
            </Box>

        </Box>
    );
};

export default Dashboard;