import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Button, Modal, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Context } from '../../Context/Context';
import { ClearIcon } from '@mui/x-date-pickers';
import SuccessPopup from '../../Common/SuccessPopup';
import apiUrl from '../../Config';

const EditEnquiries = ({ open, onClose, ticketNumber, handleSearch }) => {
    const { token, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage, } = useContext(Context);
    const [enquiryData, setEnquiryData] = useState({
        id: "",
        name: "",
        email: "",
        reasonId: "",
        description: "",
        status: "",
        resolution: ""
    });
    const [reasons, setReasons] = useState([]);
    const [error, setError] = useState("");

    // const handleClose = () => {
    //     setEnquiryData({
    //         id: "",
    //         name: "",
    //         email: "",
    //         reasonId: "",
    //         description: "",
    //         status: "",
    //         resolution: ""
    //     });
    //     setError("");
    //     onClose();
    // };

    const getEnquiryDetails = async () => {
        try {
            const response = await axios.get(apiUrl + `/tickets/getticket?ticketNumber=${ticketNumber}`);
            if (response.data.status === true) {
                setEnquiryData(response.data.faqs);
            } else {
                setError("Failed to fetch enquiry details");
            }
        } catch (error) {
            setError("Something went wrong while getting enquiry details");
        }
    };
    const handleGetReasons = async () => {
        try {
            const response = await axios.get(apiUrl + `/tickets/getticketreasons`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response?.data?.status === true) {
                setReasons(response?.data?.reasons);
            } else {
                setError("Failed to fetch reasons");
            }
        } catch (error) {
            setError("Something went wrong while fetching reasons",);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEnquiryData((prev) => ({ ...prev, [name]: value }));
        setError("");
    };
    const preventSpace = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiUrl + `/tickets/update`, enquiryData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message)
                setSuccessPopup(true)
                onClose()
                handleSearch()

            } else {
                setError("Failed to update enquiry details");
            }
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setError(error?.response?.data?.message || "Something went wrong while updating enquiry");
            }
        }
    };

    const enquiryupdateStatus = ["Started", "Success", "Cancelled", "Completed"];


    useEffect(() => {
        handleGetReasons();
        if (ticketNumber) {
            getEnquiryDetails();
        }
    }, [ticketNumber]);

    return (
        <Box>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100vh" }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            bgcolor: "background.paper",
                            borderRadius: 2,
                            boxShadow: 24,
                            p: 2,
                            width: "400px",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                            <Typography variant="h5" sx={{ textAlign: "center" }} >
                                Enquiries Form
                            </Typography>
                            <ClearIcon onClick={onClose} sx={{ cursor: "pointer" }} />
                        </Box>

                        <Box component='form' onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField fullWidth onKeyDown={preventSpace} size='small' label="Name" name="name" value={enquiryData?.name} onChange={handleChange} required />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth label="Email" onKeyDown={preventSpace} size='small' name="email" value={enquiryData?.email} onChange={handleChange} required />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel>Reason</InputLabel>
                                        <Select
                                            name="reasonId"
                                            value={enquiryData?.reasonId}
                                            onChange={handleChange}
                                            required
                                            label="Reason"
                                        >
                                            {reasons?.map(reason => (
                                                <MenuItem key={reason?.id} value={reason?.id}>{reason?.reason}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            name="status"
                                            value={enquiryData?.status}
                                            onChange={handleChange}
                                            required
                                            label='Status'
                                        >
                                            {enquiryupdateStatus?.map((status, index) => (
                                                <MenuItem key={status} value={index + 1}>{status}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth size='small' onKeyDown={preventSpace} label="Description" name="description" value={enquiryData?.description} onChange={handleChange} multiline rows={3} />
                                </Grid>
                                <Grid item xs={12} size='small'>
                                    <TextField fullWidth label="Resolution" onKeyDown={preventSpace} name="resolution" value={enquiryData?.resolution} onChange={handleChange} multiline rows={3} />
                                </Grid>

                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Button type="submit" variant="contained" color="primary">Update Enquiry</Button>
                                </Grid>
                            </Grid>
                        </Box>
                        {error && <p style={{ color: 'red' }}>{error}</p>}

                    </Box>

                </Grid>
            </Modal>
            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}

        </Box>
    );
};

export default EditEnquiries;
