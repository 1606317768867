
import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, MenuItem, Select, FormControl, InputLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Modal, TextField, IconButton } from '@mui/material';
import axios from 'axios';
import apiUrl from '../../Config';
import { Context } from '../../Context/Context';
import { Await, useNavigate } from 'react-router-dom';
import { Pencil, Trash2 } from 'lucide-react';

const Prices = () => {
    const [searchId, setSearchId] = useState('');
    const [pricesList, setPricesList] = useState([]);
    const [classificationType, setClassificationType] = useState([]);
    const [classificationName, setClassificationName] = useState('');
    const [selectedClassificationType, setSelectedClassificationType] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [selectedZone, setSelectedZone] = useState('');
    const [subServices, setSubServices] = useState([]);
    const [selectedSubService, setSelectedSubService] = useState('');
    const [miniServices, setMiniServices] = useState([]);
    const [selectedMiniService, setSelectedMiniService] = useState('');
    const [miniServiceError, setMiniServiceError] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { zones, token, serviceTypes } = useContext(Context);
    const navigate = useNavigate()


    const searchPrices = async () => {
        setLoading(true);
        try {
            const response = await axios.get(apiUrl + `/servicerates/searchrates?serviceId=${searchId}&classificationTypes=${selectedClassificationType}`);
            setPricesList(response.data.results);
        } catch (error) {
            console.error('Error searchPrices:', error);
        } finally {
            setLoading(false);
        }
    };

    const getClassificationTypes = async () => {
        try {
            const response = await axios.get(apiUrl + '/servicerates/getserviceclassifications');
            setClassificationType(response.data.classifications);
        } catch (error) {
            console.error('Error fetching classification types:', error);
        }
    };

    const fetchSubServices = async () => {
        if (!selectedService || !selectedZone) return;
        setLoading(true);
        setError('');
        setSubServices([]);
        try {
            const response = await axios.get(`${apiUrl}/servicetypes/0/getservicesubtypesbyserviceidwithzoneid?serviceId=${selectedService}&zoneId=${selectedZone}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const subservices = response.data.subservices;
            if (subservices?.length > 0) {
                setSubServices(subservices);
            } else {
                setError('No subservices available for the selected service and zone.');
            }
        } catch (err) {
            console.error('Error fetching subservices:', err);
        } finally {
            setLoading(false);
        }
    };

    const fetchMiniServices = async () => {
        if (!selectedSubService) return;
        setMiniServices([]);
        setMiniServiceError('');
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/servicetypes/getminiservices?subServiceId=${selectedSubService}`);
            const miniServicesData = response.data;
            if (miniServicesData?.length > 0) {
                setMiniServices(miniServicesData);
            } else {
                setMiniServiceError('Mini services not available.');
            }
        } catch (err) {
            setMiniServiceError('An error occurred while fetching mini services.');
        } finally {
            setLoading(false);
        }
    };

    const handleEditPrices = (priceDetails) => {
        const PriceDetails = { priceDetails, selectedService, selectedSubService, selectedZone, selectedMiniService, selectedClassificationType }
        navigate('/addPrice', { state: { PriceDetails } })
    }

    const handleDetleteByid = async (id) => {
        try {
            const response = await axios.post(`https://api.sevaki.in/servicerates/deleterate`, [id])
        } catch (error) {
            console.log(error, "error")
        }
    }

    useEffect(() => {
        if (selectedMiniService) {
            setSearchId(selectedMiniService);
        } else if (selectedSubService) {
            setSearchId(selectedSubService);
        } else if (selectedService) {
            setSearchId(selectedService);
        }
    }, [selectedService, selectedSubService, selectedMiniService]);

    useEffect(() => {
        getClassificationTypes();
    }, []);

    useEffect(() => {
        fetchSubServices();
    }, [selectedService, selectedZone]);

    useEffect(() => {
        fetchMiniServices();
    }, [selectedSubService]);

    useEffect(() => {
        if (selectedClassificationType) {
            const selectedType = classificationType.find((type) => type.module === selectedClassificationType);
            if (selectedType) {
                if (selectedType.id === 1) {
                    setClassificationName('Service');
                } else if (selectedType.id === 2) {
                    setClassificationName('SubService');
                } else if (selectedType.id === 3) {
                    setClassificationName('MiniService');
                } else {
                    setClassificationName('MicroService');
                }
            }
        }
    }, [selectedClassificationType, classificationType]);

    const displayService = serviceTypes.filter((data) => {
        return data.id === selectedService;
    });

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" color="initial" fontWeight={600} mb={1}>Filter Prices</Typography>
                <Button variant="contained" color="primary" onClick={() => navigate('/addPrice')}>
                    Add Price
                </Button>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Box mt={2} sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>

                        <FormControl sx={{ minWidth: 180 }} size="small">
                            <InputLabel id="classification-type-label">Classification Type</InputLabel>
                            <Select
                                labelId="classification-type-label"
                                value={selectedClassificationType}
                                onChange={(e) => setSelectedClassificationType(e.target.value)}
                                label="Classification Type"
                            >
                                {classificationType.map((type) => (
                                    <MenuItem key={type.id} value={type.module}>
                                        {type.module}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: 180 }} size="small">
                            <InputLabel id="service-select-label">Select Service</InputLabel>
                            <Select
                                labelId="service-select-label"
                                value={selectedService}
                                onChange={(e) => setSelectedService(e.target.value)}
                                label="Select Service"
                            >
                                {serviceTypes?.map((service) => (
                                    <MenuItem key={service.id} value={service.id}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: 180 }} size="small">
                            <InputLabel id="zone-select-label">Select Zone</InputLabel>
                            <Select
                                labelId="zone-select-label"
                                value={selectedZone}
                                onChange={(e) => setSelectedZone(e.target.value)}
                                label="Select Zone"
                            >
                                {zones?.map((zone) => (
                                    <MenuItem key={zone.id} value={zone.id}>
                                        {zone.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {subServices.length > 0 && (
                            <FormControl sx={{ minWidth: 180 }} size="small">
                                <InputLabel id="subservice-select-label">Select Subservice</InputLabel>
                                <Select
                                    labelId="subservice-select-label"
                                    value={selectedSubService}
                                    onChange={(e) => setSelectedSubService(e.target.value)}
                                    label="Select Subservice"
                                >
                                    {subServices.map((subservice) => (
                                        <MenuItem key={subservice.id} value={subservice.id}>
                                            {subservice.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {error && <Typography color="error">{error}</Typography>}

                        {miniServices.length > 0 ? (
                            <FormControl sx={{ minWidth: 180 }} size="small">
                                <InputLabel id="miniservice-select-label">Select Mini Service</InputLabel>
                                <Select
                                    labelId="miniservice-select-label"
                                    value={selectedMiniService}
                                    onChange={(e) => setSelectedMiniService(e.target.value)}
                                    label="Select Mini Service"
                                >
                                    {miniServices.map((miniService) => (
                                        <MenuItem key={miniService.id} value={miniService.id}>
                                            {miniService.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            miniServiceError && <Typography color="error" mt={2}>{miniServiceError}</Typography>
                        )}
                    </Box>

                    <Box mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={searchPrices}
                            disabled={!selectedService || !selectedZone || !selectedClassificationType}
                        >
                            Search Rates
                        </Button>
                    </Box>
                </Box>

                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <CircularProgress />
                    </Box>
                )}

                <Box>
                    <Box>
                        {pricesList.length > 0 ? (
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h4" color="initial" fontWeight={600} mt={2}>List of Prices</Typography>
                                </Box>
                                <TableContainer sx={{ mt: 1, border: '1px solid lightgrey' }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="price details table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Service</TableCell>
                                                <TableCell >{classificationName}</TableCell>
                                                <TableCell >Classification Types</TableCell>
                                                <TableCell >{classificationName} ID</TableCell>
                                                <TableCell >No. of Adults</TableCell>
                                                {/* <TableCell >No. of Infants</TableCell>
                                                <TableCell >No. of Kids</TableCell> */}
                                                <TableCell >No. of Items</TableCell>
                                                <TableCell >No. of Visits</TableCell>
                                                <TableCell >MinRoomAreainSqft</TableCell>
                                                <TableCell >MaxRoomAreainSqft</TableCell>
                                                <TableCell >Base Rate</TableCell>
                                                <TableCell >Discount</TableCell>
                                                {/* <TableCell >Membership Discount</TableCell> */}
                                                <TableCell >Estimated Rate</TableCell>
                                                {/* <TableCell >GST</TableCell>
                                                <TableCell >Platform Fee</TableCell> */}
                                                <TableCell >Final Price</TableCell>
                                                <TableCell >Professional Price</TableCell>
                                                <TableCell >Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {pricesList?.map((priceDetails) => (
                                                <TableRow key={priceDetails?.serviceRate?.id}>
                                                    <TableCell>{displayService[0]?.name}</TableCell>
                                                    <TableCell>{priceDetails?.serviceName}</TableCell>
                                                    <TableCell>{
                                                        classificationName
                                                    }</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.serviceId}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.noOfAdults}</TableCell>
                                                    {/* <TableCell>{priceDetails?.serviceRate?.noOfInfants}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.noOfKids}</TableCell> */}
                                                    <TableCell>{priceDetails?.serviceRate?.noOfItems}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.noOfVisits}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.minRoomAreainSqft}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.minRoomAreainSqft}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.baseRate}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.discount}</TableCell>
                                                    {/* <TableCell>{priceDetails?.serviceRate?.membershipDiscountAmount}</TableCell> */}
                                                    <TableCell>{priceDetails?.serviceRate?.estimatedRate}</TableCell>
                                                    {/* <TableCell>{priceDetails?.serviceRate?.gst}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.platformFee}</TableCell> */}
                                                    <TableCell>{priceDetails?.serviceRate?.finalPrice}</TableCell>
                                                    <TableCell>{priceDetails?.serviceRate?.professionalPrice}</TableCell>

                                                    <TableCell>
                                                        <IconButton color='primary' >
                                                            <Pencil size={16} onClick={() =>
                                                                handleEditPrices(priceDetails)
                                                            } />
                                                        </IconButton>
                                                   
                                                        <IconButton color='error'>
                                                            <Trash2 size={16} onClick={() =>
                                                                handleDetleteByid(priceDetails?.serviceRate?.id)
                                                            } />
                                                        </IconButton>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                                <Typography color="error" mt={2}>
                                    Select the fields to see the price details available.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

        </Box >
    );
};

export default Prices;
