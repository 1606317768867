import { Box, Button, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, typographyClasses } from '@mui/material'

import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../Context/Context'
import axios from 'axios'
import { Axis3D, Pencil, RotateCcw, Trash2 } from 'lucide-react'
import EditEnquiries from './EditEnquiries'
import SuccessPopup from '../../Common/SuccessPopup'
import DeleteConfirm from '../../Common/DeleteConfirm'
import apiUrl from '../../Config'


const Enquiries = () => {
    const { token, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage,
        itemIdToDelete,
        setItemIdToDelete,
        isConfirmDeleteModelOpen, setIsConfirmDeleteModelOpen } = useContext(Context)
    const [pageNumber, setPageNumber] = useState(1);
    const [enquiries, setEnquiries] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [selectedFields, setSelectedFields] = useState([]);
    // const [searchInput, setSearchInput] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [seletedReason, setSeletedReason] = useState('')
    const [reasons, setReasons] = useState('')
    const [selectStatus, setSelectStatus] = useState('')
    const [error, setError] = useState('')
    const [openModal, setOpenModal] = useState(false);
    const [ticketNumber, setTicketNumber] = useState('')
    const [filterData, setFilterData] = useState({
        reasonId: "",
        ticketNumber: "",
        ticketStatus: "",
        email: "",
        fromDate: "",
        toDate: "",
    });

    const handleEditEnquires = (id) => {
        setOpenModal(true);
        setTicketNumber(id)

    }

    const handleGetReasons = async () => {
        try {
            const response = await axios.get(apiUrl + `/tickets/getticketreasons`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (response?.data?.status === true) {
                setReasons(response?.data?.reasons)
            }
        } catch (error) {
            setError("something went be wrong".error)
        }
    }

    // Handle changes in form inputs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const fieldOptions = [
        { label: "ReasonId", value: "reasonId" },
        { label: "Ticket Number", value: "ticketNumber" },
        { label: "Ticket Status", value: "ticketStatus" },
        { label: "Email", value: "email" },
        { label: "From Date", value: "fromDate" },
        { label: "To Date", value: "toDate" },
    ];

    const handleFieldChange = (event) => {
        const { value } = event.target;
        setFilterOpen(false);
        const removedFields = selectedFields.filter((field) => !value.includes(field));
        removedFields.forEach((field) => {
            if (field in filterData) {
                setFilterData((prevState) => ({
                    ...prevState,
                    [field]: "",
                }));
            }
        });
        setSelectedFields(value);
    };

    const handleClearFields = () => {

        setSelectedFields([]);
        setFilterData({
            reasonId: "",
            ticketNumber: "",
            ticketStatus: "",
            email: "",
            fromDate: "",
            toDate: "",
        });
        handleSearchDetails(false)
        // handleSearch()
    };
    const handleSearchDetails = (seletedFieldsData = true) => {
        searchEnquiries(seletedFieldsData);
        setPageNumber(1)
    }
    const enquiryupdateStatus = ["Started", "Success", "Cancelled", "Completed"]


    const searchEnquiries = async (seletedFieldsData = true) => {
        setLoading(true);
        const requestData = {
            pageSize: 5,
            pageNumber: pageNumber,
            ...(selectedFields.includes("reasonId") && seletedFieldsData && { reasonId: seletedReason }),
            ...(selectedFields.includes("ticketNumber") && seletedFieldsData && { ticketNumber: filterData.ticketNumber }),
            ...(selectedFields.includes("ticketStatus") && seletedFieldsData && { ticketStatus: filterData.ticketStatus }),
            ...(selectedFields.includes("email") && seletedFieldsData && { email: filterData.email }),
            ...(selectedFields.includes("fromDate") && seletedFieldsData && { fromDate: filterData.fromDate }),
            ...(selectedFields.includes("toDate") && seletedFieldsData && { toDate: filterData.toDate }),


        }
        try {
            const response = await axios.post(apiUrl + `/tickets/searchtickets`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setHasMore(response?.data?.response?.ticketRecords?.length === 5);
            if (response?.data?.status === true) {
                setEnquiries(response?.data?.response.ticketRecords)
            }
        } catch (error) {
            setError("something went be wrong", error)

        } finally {
            setLoading(false);
        }
    }

    const handleNextPage = () => {
        if (hasMore) {
            setPageNumber(pageNumber + 1);
        }
    }

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const confirmDeleteEnquiry = (ticketNumber) => {
        setItemIdToDelete(ticketNumber)
        setIsConfirmDeleteModelOpen(true)
    }

    const handleDeleteEnquiry = async () => {
        setIsConfirmDeleteModelOpen(false)
        try {
            const response = await axios.delete(apiUrl + `/tickets/${itemIdToDelete}`
                , {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message)
                setSuccessPopup(true)
                handleSearchDetails()
            }
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setError(error?.response?.data?.message || "Something went wrong");
            }
        }
    }

    useEffect(() => {
        handleGetReasons()
    }, [])
    useEffect(() => {
        searchEnquiries()
    }, [pageNumber])

    return (
        <>

            <Typography variant="h4" fontWeight={600} mb={2}>
                Enquiries List
            </Typography>
            <Divider />

            <Grid sx={{ display: "flex", mt: "20px", gap: "10px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel>Select Filters</InputLabel>
                            <Select
                                multiple
                                value={selectedFields}
                                onChange={handleFieldChange}
                                label="Select Filters"
                                open={filterOpen}
                                onOpen={() => setFilterOpen(true)}
                                onClose={() => setFilterOpen(false)}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                {fieldOptions.map((field) => (
                                    <MenuItem key={field.value} value={field.value}>
                                        {field.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {selectedFields.includes("reasonId") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel>ReasonId</InputLabel>
                                <Select
                                    value={seletedReason}
                                    onChange={(e) => setSeletedReason(e.target.value)}
                                    label="reasonId"
                                    name="reasonId"
                                >

                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {reasons?.map((reason) => (
                                        <MenuItem key={reason?.id} value={reason?.id}>
                                            {reason?.reason}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {selectedFields.includes("ticketNumber") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Ticket Number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="ticketNumber"
                                value={filterData.ticketNumber}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("ticketStatus") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel>Ticket Status</InputLabel>
                                <Select
                                    value={selectStatus}
                                    onChange={(e) => setSelectStatus(e.target.value)}
                                    label="ticketStatus"
                                    name="ticketStatus"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>

                                    {enquiryupdateStatus?.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {status}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>


                    )}

                    {selectedFields.includes("email") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="email"
                                value={filterData.email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("fromDate") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="From Date"
                                type="date"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="fromDate"
                                value={filterData.fromDate}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("toDate") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="To Date"
                                type="date"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="toDate"
                                value={filterData.toDate}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    )}

                    {/* Select which fields to display */}

                </Grid>
                <Grid item xs={12} sm={6} md={2} sx={{ textAlign: "right", mb: "10px", }}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                        <Button onClick={searchEnquiries} variant="contained">
                            Search
                        </Button>
                        {selectedFields.length > 0 && (
                            <Grid item xs={12} sm={6} md={2}>
                                <Button variant="contained" onClick={handleClearFields} color="#e13737">
                                    <RotateCcw color="#e13737" />
                                </Button>
                            </Grid>
                        )}
                    </Box>
                </Grid>
            </Grid>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    <TableContainer component={Paper} sx={{ mt: "15px" }}>
                        {enquiries?.length > 0 ? (
                            <>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{padding:"8px" }}>S.No</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Name</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Email</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Description</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Ticket Reason</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Ticket Number</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Ticket Status</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Resolution</TableCell>
                                            <TableCell sx={{padding:"8px"}}>Created On</TableCell>
                                            <TableCell align="right" sx={{padding:"8px"}}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {enquiries?.map((tickets, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{padding:"11px "}}>{index + 1}</TableCell>
                                                <TableCell sx={{padding:"11px"}}>{tickets?.ticket?.name}</TableCell>
                                                <TableCell sx={{padding:"11px"}}>{tickets?.ticket?.description}</TableCell>
                                                <TableCell sx={{padding:"11px "}}>{tickets?.ticket?.email}</TableCell>
                                                <TableCell sx={{padding:"11px "}}>{tickets?.ticketReason}</TableCell>
                                                <TableCell sx={{padding:"11px "}}>{tickets?.ticket?.ticketNumber}</TableCell>
                                                <TableCell sx={{padding:"11px"}}>
                                                    {tickets?.ticketStatus}
                                                </TableCell>
                                                <TableCell sx={{padding:"11px 0px"}}>
                                                    {tickets?.ticket?.resolution}
                                                </TableCell>
                                                <TableCell sx={{padding:"11px 0px"}}>{new Date(tickets?.ticket?.createdOn).toLocaleDateString()}</TableCell>

                                                <TableCell align="right">
                                                    <IconButton color="primary" onClick={() => handleEditEnquires(tickets?.ticket?.ticketNumber)} >
                                                        <Pencil size={18} />
                                                    </IconButton>
                                                    <IconButton color="error"  onClick={() => confirmDeleteEnquiry(tickets?.ticket?.ticketNumber)} >
                                                        <Trash2 size={18} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        ) : (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                                    No data found. Please select the filters to refine your search.
                                </Typography>
                            </Box>
                        )}
                    </TableContainer>

                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                        <Button onClick={handlePreviousPage} disabled={pageNumber === 1} variant="outlined" >  Previous </Button>
                        <Button onClick={handleNextPage} disabled={!hasMore} variant="outlined" sx={{ ml: 2 }}  >  Next </Button>
                    </Box>
                </Box>
            )}
            <EditEnquiries open={openModal} onClose={() => setOpenModal(false)} ticketNumber={ticketNumber} handleSearch={handleSearchDetails} />
            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
            <DeleteConfirm
                open={isConfirmDeleteModelOpen}
                onClose={() => setIsConfirmDeleteModelOpen(false)}
                onConfirm={handleDeleteEnquiry}
            />

        </>
    )
}

export default Enquiries
