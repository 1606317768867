
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import apiUrl from '../../Config';
import {
    Box, Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, TextField,
    Modal,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Divider
} from '@mui/material';
import { Context } from '../../Context/Context';
import { Pencil, Trash2 } from 'lucide-react';

const CancelAndRefund = () => {
    const { token } = useContext(Context);
    const [cancelRefundData, setCancelRefundData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [selectedTransactionType, setSelectedTransactionType] = useState("UserRefund");
    const [showPopup, setShowPopup] = useState(false);
    const [error, setError] = useState()
    const [formData, setFormData] = useState({
        transactionId: "",
        resolution: "",
        status: ""
    })

    // const handleOpenpopup = () => {
    //     setFormData({
    //         transactionId: "",
    //         resolution: "",
    //         status: ""
    //     });
    //     setShowPopup(true);
    //     setError("");
    // };

    const handleClosepopup = () => {
        setShowPopup(false);
    };
    const handleNextPage = () => {
        if (hasMore) setPageNumber(pageNumber + 1);
    };


    const handleEditTransaction = (record) => {
        setFormData({
            transactionId: record.transaction.id,
            resolution: record.transaction.transactionResolution,
            status: record.transaction.status
        });
        setShowPopup(true);
    }

    // habdle change the form data
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setError("");
    };
    //  
    const handlePreviousPage = () => {
        if (pageNumber > 1) setPageNumber(pageNumber - 1);
    };

    const bookingTransactionType = ["UserPayment", "PaymentToWorker", "UserRefund", "Cancellation",
        "UserMembershipPayment", "UserPaymentFromWorkerQR", "RedeemAmountByUser", "UserRefferal"];



    // get the cancl and refund transactions
    const getcancelRefundData = async () => {
        setLoading(true);
        const requestData = {
            pageSize: 5,
            transactionType: selectedTransactionType,
            pageNumber: pageNumber,
        };
        try {
            const response = await axios.post(`${apiUrl}/api/transaction/searchtransactions`, requestData, {
                headers: {
                    "Content-Type": "application/json-patch+json",
                    Authorization: `Bearer ${token}`,
                },
            });
            setHasMore(response?.data?.response?.transactionRecords?.length === 5);
            if (response?.data?.status === true) {
                setCancelRefundData(response?.data?.response?.transactionRecords);
            }
        } catch (error) {
            setError("something went be wrong:", error);
        } finally {
            setLoading(false);
        }
    };
  
    // handle update refund and cancel transaction
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiUrl + `/api/transaction/updatetransaction`, formData, {
                headers: {
                    "Content-Type": "application/json-patch+json",
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.data.status === true) {
                setShowPopup(false)
                getcancelRefundData()
            }
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                // setSuccesMessage(error?.response?.data?.title)
                // setSuccessPopup(true)
            } else {
                setError(error?.response?.data?.message || "Something went wrong");
            }
        }
    }

    const statusMapping = {
        1: "Started",
        2: "Success",
        3: "Cancelled",
    };

    const status = [
        "Started",
        "Success",
        "Cancelled",
    ]

    useEffect(() => {
        getcancelRefundData();
    }, [pageNumber,selectedTransactionType]);


    return (
        <Box>
            <Grid container spacing={2} sx={{ mb: "20px",display:'flex',justifyContent:"space-between" }}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h4" component="h6">Cancelled & Refund</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>Transaction Type</InputLabel>
                        <Select
                            value={selectedTransactionType}
                            onChange={(e) => setSelectedTransactionType(e.target.value)}
                            label="Transaction Type"
                            name="TransactionType"
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {bookingTransactionType?.map((reason) => (
                                <MenuItem key={reason?.id} value={reason}>
                                    {reason}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>





            <Divider />
            {error && (
                <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                </Grid>
            )}
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ mt: "15px" }}>
                    {cancelRefundData?.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Transaction ID</TableCell>
                                    <TableCell>Transaction Type</TableCell>
                                    <TableCell>Transaction Amount</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Created On</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cancelRefundData.map((record, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{record?.firstName}</TableCell>
                                        <TableCell>{record?.lastName}</TableCell>
                                        <TableCell>{record?.transaction?.id}</TableCell>
                                        <TableCell>
                                            {bookingTransactionType[record?.transaction?.transactionType - 1] || ""}
                                        </TableCell>
                                        <TableCell>{record?.transaction?.transactionAmount}</TableCell>
                                        <TableCell>{statusMapping[record?.transaction?.status] || ""}</TableCell>
                                        <TableCell>{new Date(record?.transaction?.createdOn).toLocaleString()}</TableCell>
                                        <TableCell align="right">
                                            <IconButton color="primary" onClick={() => handleEditTransaction(record)}>
                                                <Pencil size={18} />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                                No data found. Please select the filters to refine your search.
                            </Typography>
                        </Box>
                    )}
                </TableContainer>
            )}

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button onClick={handlePreviousPage} disabled={pageNumber === 1} variant="outlined">Previous</Button>
                <Button onClick={handleNextPage} disabled={!hasMore} variant="outlined" sx={{ ml: 2 }}>Next</Button>
            </Box>

            {/* Edit Transaction Modal */}
            <Modal open={showPopup} onClose={handleClosepopup}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100vh" }}
                >
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Edit Transaction
                            </Typography>

                            <Box component="form" onSubmit={handleUpdate} >
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Transaction ID"
                                            name="transactionId"
                                            onChange={handleChange}
                                            value={formData?.transactionId}
                                            size='small'
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="resolution"
                                            label="resolution"
                                            onChange={handleChange}
                                            value={formData?.resolution}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                name="status"
                                                value={formData?.status}
                                                onChange={handleChange}
                                                required
                                                label='Status'
                                            >
                                                {status?.map((status, index) => (
                                                    <MenuItem key={status} value={index + 1}>{status}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                            <Button onClick={handleClosepopup} color="error" variant='contained'>
                                                Cancel
                                            </Button>
                                            <Button type='submit' color="primary" variant="contained" sx={{ ml: 2 }}>
                                                Update
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>




                        </Box>
                    </Grid>
                </Grid>
            </Modal>
        </Box>
    );
};

export default CancelAndRefund;
