import "./App.css";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import Login from "./Forms/Login";
import PageRoutes from "./Routes/PageRoutes";
import BookingList from "./Pages/Bookings/BookingList";
import { useEffect } from "react";

function App() {
  const theme = createTheme({
    palette: {
      success: {
        main: "#4BCE97",
        background: "#EDFAF5",
      },
      primary: {
        main: "#7E62CC",
        contrastText: "#fff",
        borderColor: "#E4E4E4",
      },

      secondary: {
        main: "#BCBCBC",
        contrastText: "#292929",
      },
      footer: {
        main: "#BCBCBC",
      },
      error: {
        main: "#FF0000",
      },
      default: {
        main: "#445371",
        background: "#44537126",
      },
    },
    typography: {
      fontFamily: ["Albert Sans"].join(","),
      fontSize: 14,
      fontWeightBold: 700,
      h1: {
        fontFamily: "Albert Sans",
        fontWeight: 300,
        fontSize: "80px",
      },
      h2: {
        fontFamily: "Albert Sans",
        fontWeight: 300,
        fontSize: "48px",
      },
      h3: {
        fontFamily: "Albert Sans",
        fontWeight: 400,
        fontSize: "40px",
      },
      h4: {
        fontFamily: "Albert Sans",
        fontWeight: 400,
        fontSize: "28px",
        lineHeight: "23.12px",
        letterSpacing: "0.00735em",
      },
      h5: {
        fontFamily: "Albert Sans",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "26.67px",
      },
      h6: {
        fontFamily: "Albert Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "25.6px",
      },
      body1: {
        fontFamily: "Albert Sans",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
        color: "#000000",
      },
      body2: {
        fontFamily: "Albert Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "20px",
        letterSpacing: "0.01071em",
        color: "#000000",
      },
      button: {
        fontFamily: "Albert Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24.5px",
        letterSpacing: "0.02857em",
        textTransform: "none",
      },
      caption: {
        fontFamily: "Albert Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "19.92px",
        letterSpacing: "0.03333em",
      },
      overline: {
        fontFamily: "Albert Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "21.33px",
        letterSpacing: "0.08333em",
        textTransform: "uppercase",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            textTransform: "none",
          },
          contained: {
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
          outlined: {
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
      },
    },
  });


  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.clear();
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);


  return (
    <>
      <ThemeProvider theme={theme}>
        <PageRoutes/>
      </ThemeProvider>
    </>
  );
}

export default App;
