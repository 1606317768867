
import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, TextField, Checkbox, FormControlLabel, MenuItem, Select, FormControl, InputLabel, Grid, IconButton, CircularProgress, Divider } from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Context } from '../../../Context/Context';
import apiUrl from '../../../Config';
import { CloseOutlined } from '@mui/icons-material';
import { ArrowLeft, Pencil } from 'lucide-react';
import SuccessPopup from '../../../Common/SuccessPopup';

const DisplaySubServices = () => {

    const { token, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage } = useContext(Context)

    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { service } = state || {};
    const [subServices, setSubServices] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        Id: 0,
        ServiceId: service?.id || '',
        Name: '',
        Description: '',
        ZoneId: 0,
        BasePrice: '',
        PlatformFeeAmount: '',
        GstPercent: '',
        ExpectedHours: '',
        ServiceClassificationTypes: 'SubService',
        ImageFile: null,
        // ImagePreview: '',
        IsActive: true,
        IsDeleted: false,
    });
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState('');
    const [imagePreview, setImagePreview] = useState()
    const [loading, setLoading] = useState(false)


    const fetchSubService = async () => {
        setLoading(true)
        try {
            const response = await axios.get(apiUrl + `/servicetypes/${service.id}/getservicesubtypes`);
            setSubServices(response.data.subservices);
        } catch (error) {
            console.log(error, "error")
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchSubService();
    }, [id]);

    useEffect(() => {
        const fetchZones = async () => {
            try {
                const response = await axios.get(apiUrl + '/zone/listzones', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
                setZones(response.data.zone || []);
            } catch (error) {
                console.error('Error fetching zones:', error);
            }
        };
        fetchZones();
    }, []);

    const handleEditClick = (subService) => {
        setFormData({
            Id: subService.id,
            ServiceId: service.id,
            Name: subService.name,
            Description: subService.description,
            ZoneId: subService.zoneId,
            BasePrice: subService.basePrice,
            PlatformFeeAmount: subService.platformFeeAmount,
            GstPercent: subService.gstPercent,
            ExpectedHours: subService.expectedHours,
            ServiceClassificationTypes: subService.serviceClassificationTypes,
            ImageFile: subService.imagePath,
            // ImagePreview: subService.imagePath,
            IsActive: subService.isActive,
            IsDeleted: subService.isDeleted,
        });
        setOpenModal(true);
        setImagePreview(apiUrl + "/" + subService.imagePath);
    };

    const resetForm = () => {
        setFormData({
            Id: '',
            ServiceId: service?.id,
            Name: '',
            Description: '',
            ZoneId: '',
            BasePrice: '',
            PlatformFeeAmount: '',
            GstPercent: '',
            ExpectedHours: '',
            ServiceClassificationTypes: '',
            ImageFile: '',
            // ImagePreview: '',
            IsActive: false,
            IsDeleted: false,
        });
        setImagePreview("")
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: ['ZoneId', 'BasePrice', 'ExpectedHours', 'Id', 'ServiceId'].includes(name) ? Number(value) : value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleZoneSelect = (e) => {
        setFormData((prev) => ({
            ...prev,
            ZoneId: e.target.value,
        }));
    };

    const handleImageChange = (event) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                ImageFile: files[0],
            }));
            setImagePreview(URL.createObjectURL(files[0]))
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('Id', formData.Id);
            formDataToSend.append('ServiceId', formData.ServiceId);
            formDataToSend.append('Name', formData.Name);
            formDataToSend.append('Description', formData.Description);
            formDataToSend.append('ZoneId', formData.ZoneId);
            formDataToSend.append('BasePrice', formData.BasePrice);
            formDataToSend.append('PlatformFeeAmount', formData.PlatformFeeAmount);
            formDataToSend.append('GstPercent', formData.GstPercent)
            formDataToSend.append('ExpectedHours', formData.ExpectedHours);
            formDataToSend.append('ServiceClassificationTypes', "SubService");
            formDataToSend.append('IsActive', formData.IsActive);
            formDataToSend.append('IsDeleted', formData.IsDeleted);
            formDataToSend.append('ImageFile.FileType', "Image");
            formDataToSend.append('ImageFile.FileDetails', formData.ImageFile);
            let response;
            if (formData.Id) {
                response = await axios.put(apiUrl + '/servicetypes/updatesubservicetype', formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
            } else {
                response = await axios.post(apiUrl + '/servicetypes/createsubservicetype', formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
            }
            if (response.data.status == true) {
                setSuccesMessage(response.data.message)
                setSuccessPopup(true)
            }
            fetchSubService();
            handleCloseModal();
            resetForm()
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleChangeZone = (event) => {
        setSelectedZone(event.target.value)
    }

    const handleRowClick = (subService) => {
        navigate(`/displayMiniServices`, { state: { subServiceId: subService.id } });
    };

    const handleAddOnClick = (subService) => {
        navigate(`/displayAddOns`, { state: { subServiceId: subService } })
    }

    return (
        <Box mt={10}>
            <ArrowLeft onClick={() => navigate(-1)} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" fontWeight={600}>Sub-Services for {service?.name}</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FormControl sx={{ minWidth: 200 }} size='small'>
                        <InputLabel id="zone-select-label">Select Zone</InputLabel>
                        <Select
                            labelId="zone-select-label"
                            value={selectedZone}
                            label="Select Zone"
                            onChange={handleChangeZone}
                            size='small'
                        >
                            <MenuItem value="">
                                <em>Select a value</em>
                            </MenuItem>
                            {zones.map((zone) => (
                                <MenuItem key={zone.id} value={zone.id}>
                                    {zone.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button variant="contained" onClick={() => { setOpenModal(true); resetForm(); }}>
                        Add SubService
                    </Button>
                </Box>

            </Box>
            <Divider sx={{ mb: 3 }} />

            {
                loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                        <CircularProgress />
                    </Box>
                ) : (

                    subServices.length ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell sx={{ width: '70px' }}>Sub Service</TableCell>
                                        <TableCell sx={{ maxWidth: '270px' }}>Description</TableCell>
                                        <TableCell sx={{ width: '60px' }}>Zone ID</TableCell>
                                        <TableCell sx={{ width: '70px' }}>Base Price</TableCell>
                                        <TableCell sx={{ width: '70px' }}>GST Percent</TableCell>
                                        <TableCell sx={{ width: '70px' }}>Platform Fee</TableCell>
                                        <TableCell sx={{ width: '100px' }}>Expected Hours</TableCell>
                                        <TableCell sx={{ width: '270px' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subServices?.filter((subService) => selectedZone === '' || subService.zoneId === selectedZone)
                                        .map((subService) => (
                                            <TableRow key={subService?.id} sx={{ cursor: 'pointer' }}>
                                                <TableCell>
                                                    <img src={apiUrl + "/" + subService?.imagePath} alt="image" width={40} />
                                                </TableCell>
                                                <TableCell>{subService?.name}</TableCell>
                                                <TableCell sx={{ maxWidth: '270px' }}>{subService?.description}</TableCell>
                                                <TableCell>{subService?.zoneId}</TableCell>
                                                <TableCell>{subService?.basePrice}</TableCell>
                                                <TableCell>{subService?.gstPercent}</TableCell>
                                                <TableCell>{subService?.platformFeeAmount}</TableCell>
                                                <TableCell>{subService?.expectedHours}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleEditClick(subService)} color='primary'>
                                                        <Pencil size={18} />
                                                    </IconButton>
                                                    <Button variant="contained" onClick={() => handleRowClick(subService)} sx={{ marginX: '5px' }}>
                                                        Mini Services
                                                    </Button>
                                                    <Button variant="contained" onClick={() => handleAddOnClick(subService?.id)}>
                                                        AddOn's
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (

                        <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                            <Typography variant="h6" color="initial">No subservices available</Typography>
                        </Box>
                    )

                )
            }




            <Modal open={openModal} onClose={handleCloseModal}>
                <Grid container justifyContent='center' alignItems='center' height='100vh'>

                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: 600,
                        width: '90%',
                        borderRadius: 2,
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h4" fontWeight={600} mb={4}>{formData.Id ? 'Edit' : 'Add'} Sub-Service</Typography>
                            <IconButton
                                onClick={() => setOpenModal(false)}
                                sx={{
                                    position: 'absolute',
                                    top: '20px',
                                    right: '20px',
                                }}
                            >
                                <CloseOutlined />
                            </IconButton>
                        </Box>

                        <Box component={'form'} onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} pt={0} sx={{ paddingTop: '0px !important' }}>
                                    <TextField label="Name" name="Name" size='small' value={formData.Name} onChange={handleChange} fullWidth margin="normal" required />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                                    <TextField label="Description" name="Description" size='small' value={formData.Description} onChange={handleChange} fullWidth margin="normal" required />
                                </Grid>

                                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                                    <FormControl fullWidth margin="normal" size='small' required>
                                        <InputLabel>Zone</InputLabel>
                                        <Select name="ZoneId" value={formData.ZoneId} onChange={handleZoneSelect}>
                                            {zones.map((zone) => (
                                                <MenuItem key={zone.id} value={zone.id}>
                                                    {zone.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                                    <TextField label="Base Price" name="BasePrice" size='small' type="number" value={formData.BasePrice} onChange={handleChange} fullWidth margin="normal" required />
                                </Grid>

                                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                                    <TextField label="GST Percent" name="GstPercent" size='small' type="number" value={formData.GstPercent} onChange={handleChange} fullWidth margin="normal" required />
                                </Grid>

                                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                                    <TextField label="Platform FeeAmount" name="PlatformFeeAmount" size='small' type="number" value={formData.PlatformFeeAmount} onChange={handleChange} fullWidth margin="normal" required />
                                </Grid>

                                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                                    <TextField label="Expected Hours" name="ExpectedHours" size='small' type="number" value={formData.ExpectedHours} onChange={handleChange} fullWidth margin="normal" required />
                                </Grid>

                                <Grid item xs={12} sm={3} >
                                    <FormControlLabel control={<Checkbox checked={formData.IsActive} onChange={handleCheckboxChange} name="IsActive" />} label="Is Active" />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel control={<Checkbox checked={formData.IsDeleted} onChange={handleCheckboxChange} name="IsDeleted" />} label="Is Deleted" />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px ' }}>
                                    <input type="file" name="ImageFile" accept='image/*' size='small' onChange={handleImageChange} required={!imagePreview} />
                                    {imagePreview && (
                                        <img src={imagePreview} alt="Preview" style={{ width: '100px', marginTop: '10px', borderRadius: '5px' }} />
                                    )}
                                </Grid>
                            </Grid>

                            <Button variant="contained" color="primary" type='submit' fullWidth sx={{ mt: 3 }}>
                                {formData.Id ? 'Update' : 'Add'} Sub-Service
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Modal>


            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}

        </Box>
    );
};

export default DisplaySubServices;

