import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Modal,
} from "@mui/material";

import apiUrl from "../../Config";
import { Context } from "../../Context/Context";
import SuccessPopup from '../../Common/SuccessPopup';
import DeleteConfirm from "../../Common/DeleteConfirm";

const UserVerificationSlot = ({ open, onClose, phoneNumber, userId }) => {
  const { token, successPopup,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage,
   
     itemIdToDelete, 
     setItemIdToDelete} = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [verificationDateTime, setVerificationDateTime] = useState("");
  const [slotId, setSlotId] = useState("");
  const [dateError, setDateError] = useState("");


  const [isModalOpen, setIsModalOpen] = useState(false);

  const getSlotDetailsById = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/userverificationslot/getuserverificationslotbyuserid?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status === true) {
        const slotDetails = response?.data?.userSlot;
        setVerificationDateTime(slotDetails?.verificationDateTime || "");
        setSlotId(slotDetails?.id);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };
  const validateDateTime = (dateTime) => {
    const selectedDateTime = new Date(dateTime);
    const currentDateTime = new Date();
    const isFutureDateTime = selectedDateTime > currentDateTime;
    const selectedHour = selectedDateTime.getHours();
    const isWithinTimeRange = selectedHour >= 7 && selectedHour < 19;
    if (!isFutureDateTime) {
      setDateError("Verification slot date and time must be above the current date.");
      return false;
    }
    if (!isWithinTimeRange) {
      setDateError("Verification  slot time must be between 7 AM and 7 PM.");
      return false;
    }
    setDateError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateDateTime(verificationDateTime)) {
      return;
    }

    try {
      if (slotId && userId) {
        const response = await axios.post(
          `${apiUrl}/userverificationslot/updateverificationslotbyadmin`, {
          userId: userId,
          phoneNumber: phoneNumber,
          dateTime: verificationDateTime,
        }, {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }

        );
        if (response?.data?.status === true) {
          setSuccesMessage(response?.data?.message);
          setSuccessPopup(true);
          getSlotDetailsById()
        }
        else {
          setError(response?.data?.message)
        }
      } else if (userId) {
        const response = await axios.post(
          `${apiUrl}/userverificationslot/createverificationslotbyadmin`,
          {
            userId: userId,
            phoneNumber: phoneNumber,
            dateTime: verificationDateTime,
          },
          {
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data?.status === true) {
          setSuccesMessage(response?.data?.message);
          setSuccessPopup(true);
          getSlotDetailsById()
        } else {
          setError(response?.data?.message)
        }
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    }
  };


  const conformDeleteVerify = () => {
    setItemIdToDelete(userId)
    setIsModalOpen(true)
  }

  const handleDelete = async () => {
    setIsModalOpen(false)
    try {
      const response = await axios.delete(
        apiUrl + `/userverificationslot/${itemIdToDelete}`,
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status === true) {
        setSuccesMessage(response?.data?.message);
        setSuccessPopup(true);
        onClose()
      } else {
        setError(response?.data?.message)
      }

      setSlotId("");
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  useEffect(() => {
    if (userId) {
      getSlotDetailsById();
    }
  }, [userId]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item xs={12} sm={8} md={6} lg={4}>

            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                width: "400px",
              }}
            >
              <Grid
                container
                spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "",
                }}
              >
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h4">User Verification Slot</Typography>
                    {/* <ClearIcon onClick={onClose} sx={{ cursor: "pointer" }} /> */}
                  </Box>
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body1">
                      Error: {error}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Phone Number"
                    type="number"
                    value={phoneNumber}
                    size="small"
                    required
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    label="Verification Date & Time"
                    type="datetime-local"
                    value={verificationDateTime}
                    onChange={(e) => {
                      setVerificationDateTime(e.target.value);
                      validateDateTime(e.target.value);
                    }}
                    required
                    error={!!dateError}
                    helperText={dateError}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ display: "flex", justifyContent: "end", gap: 3 }}
                >
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {slotId ? "Update Slot" : "Create Slot"}
                  </Button>
                  {slotId && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={conformDeleteVerify}
                    >
                      Delete Slot
                    </Button>
                  )}
                  <Button onClick={onClose} color="error" variant="contained">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}

      <DeleteConfirm
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}

      />
    </>
  );
};

export default UserVerificationSlot;
