import { Box, Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel, Grid, FormControlLabel, Checkbox, CircularProgress, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef, useContext } from "react";
import apiUrl from "../../Config";
import { Context } from "../../Context/Context";

import HtmlEditor from "../../Common/HtmlEditor";
import SuccessPopup from "../../Common/SuccessPopup";
import DeleteConfirm from "../../Common/DeleteConfirm";

const Membership = () => {
  const { token, zones, successPopup,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage, description, setDescription,
    isConfirmDeleteModelOpen,
    setIsConfirmDeleteModelOpen,
    itemIdToDelete,
    setItemIdToDelete } = useContext(Context)

  const [membershipPlanList, setMembershipPlanList] = useState([]);
  const [selectedZoneId, setSelectedZoneId] = useState("");
  const [errors, setErrors] = useState("")
  const [showForm, setShowForm] = useState(false);
  const [newMembership, setNewMembership] = useState({
    id: 0,
    zoneId: selectedZoneId,
    heading: "",
    caption: "",
    description: "",
    price: "",
    durationInMonths: "",
    isActive: ""
  });
  const [editingMembership, setEditingMembership] = useState(false);
  const [zoneName, setZoneName] = useState([]);
  const [loading, setLoading] = useState(false)

  const descriptionRef = useRef(null);

  const fetchMembershipPlan = async (zoneId) => {
    setLoading(true)
    try {
      const response = await axios.post(
        apiUrl + `/usermembershipplan/listmembershipplans?zoneId=${zoneId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
      );
      if (response.data.status === true) {
        setMembershipPlanList(response.data.plans);
      }

    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrors(error?.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    const handleZonesList = async () => {
      try {
        const response = await axios.get(apiUrl + "/zone/listzones", {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        setZoneName(response.data.zone);

        if (descriptionRef.current && descriptionRef.current.innerHTML !== newMembership.description) {
          descriptionRef.current.innerHTML = newMembership.description;
        }
      } catch (error) {
        if (error?.response?.data?.status === 401) {
          setSuccesMessage(error?.response?.data?.title)
          setSuccessPopup(true)
        } else {
          setErrors(error?.response?.data?.message || "Something went wrong");
        }
      }
    };

    handleZonesList();
  }, [selectedZoneId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMembership((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const parsedMembership = {
        ...newMembership,
        zoneId: parseInt(newMembership.zoneId, 10),
        price: parseFloat(newMembership.price),
        durationInMonths: parseInt(newMembership.durationInMonths, 10),
        description: description
      };

      if (editingMembership) {
        const response = await axios.post(
          apiUrl + "/usermembershipplan/updatemembershipplan",
          parsedMembership, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
        );
        if (response?.data?.status === true) {
          setSuccesMessage(response?.data?.message)
          setSuccessPopup(true)
          fetchMembershipPlan(selectedZoneId);
        }
        setMembershipPlanList((prev) => [...prev, response.data]);
      } else {
        const response = await axios.post(
          apiUrl + "/usermembershipplan/createmembershipplan",
          parsedMembership, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
        );
        if (response?.data?.status === true) {
          setSuccesMessage(response?.data?.message)
          setSuccessPopup(true)
          fetchMembershipPlan(selectedZoneId);
        }
        setMembershipPlanList((prev) => [...prev, response.data]);
      }

      setShowForm(false);
      resetForm()
      setEditingMembership(null);

    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrors(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  const handleEditClick = (plan) => {
    setNewMembership(plan);
    setSelectedZoneId(plan.zoneId);
    setShowForm(true);
    setEditingMembership(plan);
    setDescription(plan.description);
  };

  const handleMembershipDelete = (planId) => {
    setIsConfirmDeleteModelOpen(true)
    setItemIdToDelete(planId)
  }

  const handleDeactivate = async () => {
    setIsConfirmDeleteModelOpen(false)
    try {
      const response = await axios.delete(apiUrl + `/usermembershipplan/${itemIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      if (response?.data?.status === true) {
        setSuccesMessage(response?.data?.message)
        setSuccessPopup(true)
        fetchMembershipPlan(selectedZoneId);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrors(error?.response?.data?.message || "Something went wrong");
      }

    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewMembership((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleZoneChange = (event) => {
    const zoneId = event.target.value;
    setNewMembership((prev) => ({ ...prev, zoneId }));
    setSelectedZoneId(zoneId);
    fetchMembershipPlan(zoneId);
  };

  const resetForm = () => {
    setNewMembership({
      id: 0,
      zoneId: selectedZoneId,
      heading: "",
      caption: "",
      description: "",
      price: "",
      durationInMonths: "",
      isActive: false
    });
    setDescription("")
  }

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h4" fontWeight={600}>Membership Plans</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel id="zone-select-label">Select Zone</InputLabel>
              <Select
                labelId="zone-select-label"
                value={selectedZoneId}
                onChange={handleZoneChange}
                label="Select Zone"
              >
                {zoneName?.map((zone) => (
                  <MenuItem key={zone?.id} value={zone?.id}>
                    {zone?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={() => {
                setShowForm((prev) => !prev);
                setEditingMembership(null);
                resetForm();
              }}
            >
              {showForm ? "Cancel" : "Add New Membership"}
            </Button>
          </Box>
        </Box>

        <Divider sx={{ mt: 3 }} />

        {showForm ? (
          <Box component='form' onSubmit={handleFormSubmit}>
            <Box>
              <Typography variant="h5" fontWeight={500} mb={2}>
                {editingMembership ? "Edit Membership Plan" : "Add New Membership Plan"}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Heading"
                    name="heading"
                    value={newMembership.heading}
                    onChange={handleInputChange}
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Caption"
                    name="caption"
                    value={newMembership.caption}
                    onChange={handleInputChange}
                    size="small"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Zone ID"
                    name="zoneId"
                    value={newMembership.zoneId}
                    onChange={handleInputChange}
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="price"
                    value={newMembership.price}
                    onChange={handleInputChange}
                    size="small"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Duration (in Months)"
                    name="durationInMonths"
                    value={newMembership.durationInMonths}
                    onChange={handleInputChange}
                    required
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControlLabel control={<Checkbox checked={newMembership.isActive} onChange={handleCheckboxChange} name="isActive" />} label="Is Active" />
                </Grid>
              </Grid>

              <Typography variant="h6" color="initial" mt={2} >Description</Typography>
              <HtmlEditor
                value={description}
                onChange={(newContent) => setDescription(newContent)}
                placeholder="Enter your description here..."
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
              >
                {editingMembership ? "Update" : "Submit"}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            {
              loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box mt={4} sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  {membershipPlanList?.map((plan, index) => (
                    <Box key={index} mb={2} p={2} border={1} borderColor="grey.300" borderRadius={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: { sm: '100%', md: '50%', lg: '29%' } }}>
                      <Box sx={{ lineHeight: 2 }}>
                        <Typography variant="h6" fontWeight={500} mb={2}><b>Zone ID:</b> {plan?.zoneId}</Typography>
                        <Typography variant="h6" fontWeight={500}><b>Heading:</b> {plan?.heading}</Typography>
                        <Typography variant="h6" fontWeight={500}><b>Caption:</b> {plan?.caption}</Typography>
                        <Typography variant="h6" fontWeight={500}><b>Price:</b> {plan?.price} Rs</Typography>
                        <Typography variant="h6" fontWeight={500}><b>Duration:</b> {plan?.durationInMonths} Months</Typography>
                        <Typography variant="h6" fontWeight={500}><b>Description:</b></Typography>
                        <Typography variant="h6" dangerouslySetInnerHTML={{ __html: plan?.description }} />
                      </Box>
                      <Box sx={{ display: 'flex' }} gap={2}>
                        <Button onClick={() => handleEditClick(plan)} variant="contained">Edit</Button>
                        <Button onClick={() => handleMembershipDelete(plan?.id)} variant="contained" color="error">
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )
            }

          </>
        )}
      </Box>

      <DeleteConfirm
        open={isConfirmDeleteModelOpen}
        onClose={() => setIsConfirmDeleteModelOpen(false)}
        onConfirm={handleDeactivate}

      />

      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}
    </>
  );
}

export default Membership;